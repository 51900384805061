<header class="main-header header--sticky"
        *ngIf="showHeaderMenu"
        id="top"
        (mouseleave)="activeMainNavElm = null"
        [class.main-header--compact]="headerIsCompact()"
        [class.main-header--no-shadow]="activeMainNavElm !== null && flyout.length >= activeMainNavElm">

    <div class="wrap">
        <div class="col-12">
            <div class="main-header__inner">

                <div class="logo__container">
                  <a [xcoLink]="'/'" class="logo">
                    <img src="/assets/svg/fn_logo.svg" alt="KLEUSBERG">
                  </a>
                </div>

                <div class="kle__container">
                  <div class="button" [innerHTML]="'Zurück zur FN-Website'" (click)="goToWebsite('https://www.f-niemann.de/')"></div>
                  <div class="kle__learn-more" (click)="goToWebsite('https://www.kleusberg.de/')">
                    <p [innerHTML]="'Mehr zum Thema: <small>www.kleusberg.de</small>'"></p>
                    <div class="svg-icon" [inlineSVG]="'logo--black.svg'"></div>
                  </div>
                </div>
            </div>
        </div>
    </div>

</header>
<app-mobile-header *ngIf="flyout && navigation && showHeaderMenu" [navigation]="navigation" [flyout]="flyout"></app-mobile-header>
