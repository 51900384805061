export const environment = {
  production: false,
  // apiUrl: 'https://d-kle-1zb.connect.xava.io',
  apiUrl: 'https://kle-o2k.prod.reaze.cloud',
  contentPath: '/xco/kleusberg',
  endpoints: {
    nav: '/de/navigation/',
    flyout: '/de/flyout/',
    sitemap: '/de/footer/',
    legal: '/de/legal/',
    search: '/search?term=',
    trendlinePrice: '/de/trendline/prices',
    trendlineFreight: '/de/trendline/freight',
    zip: '/de/zip',
    form: 'https://backend.kleusberg.de/de/trendline/pdf-niemann',
    personImage: '/de/personimage'
  }
};
