<footer class="main-footer main-footer__niemann main-footer--grey main-footer--center">
  <ul>
    <li><a [href]="'https://www.f-niemann.de/agb/'"
           [target]="'_blank'"
           [innerHTML]="'AGB'"></a></li>
    <li><a [href]="'https://www.f-niemann.de/agb-mietvertragsbedingungen/'"
           [target]="'_blank'"
           [innerHTML]="'AGB - Mietvertragsbedingungen'"></a></li>
    <li><a [href]="'https://www.f-niemann.de/kontakt/'"
           [target]="'_blank'"
           [innerHTML]="'Kontakt'"></a></li>
    <li><a [href]="'https://www.f-niemann.de/impressum/'"
           [target]="'_blank'"
           [innerHTML]="'Impressum'"></a></li>
    <li><a [href]="'https://www.f-niemann.de/datenschutzerklaerung/'"
           [target]="'_blank'"
           [innerHTML]="'Datenschutzerklärung'"></a></li>
  </ul>
</footer>
