import {Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, AfterViewInit} from '@angular/core';
import { Options } from 'ng5-slider';

@Component({
  selector: 'ranger-input',
  templateUrl: './ranger-input.component.html',
  styles: []
})
export class RangerInputComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('ranger', { static: true }) rangerRef;
  @ViewChild('handlebar', { static: true }) handlebarRef: ElementRef;
  @Input() rangeInput;
  @Input() options;
  @Input() rangeFormControl;
  @Input() steps: boolean;
  default: Options = {
    floor: 0,
    ceil: 1000,
    showSelectionBar: true,
    hideLimitLabels: true,
    selectionBarGradient: {
      from: '#b9cfe1',
      to: '#005093'
    },
    showTicks: true
  };

  @Output() rangeValue: EventEmitter<number> = new EventEmitter<number>();
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  divLength;
  iconMoved = false;
  constructor() {
  }

  ngOnInit() {
    this.rangerRef.elementRef.nativeElement.classList.remove('ng5-slider');
    this.rangerRef.elementRef.nativeElement.querySelector('.ng5-slider-pointer').appendChild(this.handlebarRef.nativeElement);
    this.iconMoved = true;
    this.setRangerOptions();
  }

  ngAfterViewInit() {
    this.manualRefresh.emit();
  }
  private  setRangerOptions() {
    if (typeof this.options.ticksArray === 'number') {
      this.options.ticksArray = this.ticksArrayCount();
    }
    this.getStepLength();
    this.options = {...this.default, ...this.options};
  }
  ngOnChanges() {
    this.setRangerOptions();
    this.manualRefresh.emit();
  }

  rangeValueOnChange(event) {
    this.rangeValue.emit(event);
  }

  private getStepLength() {
    this.updateOptions({step: this.steps ? this.divLength : false});
  }

  private updateOptions(props: object) {
    const newOptions: Options = Object.assign({}, this.options);
    for (const key in props) {
      if (props.hasOwnProperty(key)) {
        newOptions[key] = props[key];
      }
    }
    this.options = newOptions;
  }

  private ticksArrayCount(): Array<number> {
    let i: number;
    const ticksArray = [];
    this.divLength = (this.options.ceil - this.options.floor) / this.options.ticksArray;
    for (i = 1; i < this.options.ticksArray; i++) {
      ticksArray.push(
        (this.divLength * i) + this.options.floor
      );
    }
    return ticksArray;
  }
}
