<h6 class="with-decor" [innerHTML]="'Raumlänge'"></h6>
<p class="align-center" [innerHTML]="'Der Systemraum darf nicht kürzer als ' + dimensions[0].content[form.controls['flanks'].value - 1].floor.toLocaleString() + ' mm sein. Die maximale Länge darf  ' + dimensions[0].content[form.controls['flanks'].value - 1].ceil.toLocaleString() + '  mm nicht überschreiten.'"></p>
<div class="form-section">
  <div class="select-dropdown__container select-dropdown__container--no-label col-12 col-mobile-landscape-9 col-ipad-portrait-6 col-desktop-smallest-3" [formGroup]="form">
      <ng-select class="select-dropdown select-dropdown--gray"
                 [items]="gridDropdowns[0].content"
                 bindLabel="label"
                 bindName="value"
                 [clearable]="false"
                 [formControlName]="gridDropdowns[0].name">
      </ng-select>
  </div>
</div>
<div class="inner-wrap" [formGroup]="form" [hidden]="form.controls['grid_unit_length'].value.value === false">
    <div class="form-section form-section--ranger col-12">
        <ranger-input [rangeFormControl]="form.controls[dimensions[0].name]"
                      [options]="dimensions[0].content[form.controls['flanks'].value - 1]"
                      [steps]="form.controls['grid_unit_length'].value.value"></ranger-input>
    </div>
</div>

<div class="align-center col-12 col-mobile-landscape-6 col-ipad-portrait-3"
     [formGroup]="form"
     [hidden]="form.controls['grid_unit_length'].value.value === true">
  <div class="form-section">
    <span [className]="'form-element__container'">
          <label [className]="'form-element__label'"
                 [attr.for]="'tc-cd-' + chosenDimensions.length.name"
                 [innerHTML]="'Bitte tragen Sie das gewünschte Fixmaß ein'"></label>
          <input class="input input--white"
                 [min]="dimensions[0].validators[0].minLength"
                 [max]="dimensions[0].validators[1].maxLength"
                 [id]="'tc-cd-' + chosenDimensions.length.name"
                 [type]="'number'"
                 [value]="form.controls[chosenDimensions.length.name].value"
                 (change)="checkDimensions(dimensions[0].validators[0].minLength, dimensions[0].validators[1].maxLength, $event, chosenDimensions.length.name)" />
          <span class="form-element__legend">mm</span>
          <span class="form-icon--edit" [inlineSVG]="'pencil-edit-button.svg'"></span>
      </span>
      <p class="form-section__legend align-center" [innerHTML]="'(min. ' + dimensions[0].content[form.controls['flanks'].value - 1].floor.toLocaleString() + 'mm, max. ' + dimensions[0].content[form.controls['flanks'].value - 1].ceil.toLocaleString() + 'mm.)'"></p>
  </div>
</div>

<h6 class="with-decor" [innerHTML]="'Raumtiefe'"></h6>
<p class="align-center" [innerHTML]="'Der Systemraum darf eine Tiefe von  ' + dimensions[1].content[form.controls['flanks'].value - 1].floor.toLocaleString() + '  mm nicht unterschreiten. Die maximale Tiefe liegt bei  ' + dimensions[1].content[form.controls['flanks'].value - 1].ceil.toLocaleString() + '  mm.'"></p>
<div class="form-section">
  <div class="select-dropdown__container select-dropdown__container--no-label col-12 col-mobile-landscape-9 col-ipad-portrait-6 col-desktop-smallest-3" [formGroup]="form">
      <ng-select class="select-dropdown select-dropdown--gray"
                 [items]="gridDropdowns[1].content"
                 bindLabel="label"
                 bindName="value"
                 [clearable]="false"
                 [formControlName]="gridDropdowns[1].name">
      </ng-select>
  </div>
</div>
<div class="inner-wrap"
     [formGroup]="form"
     [hidden]="form.controls['grid_unit_depth'].value.value === false">
    <div class="form-section form-section--ranger col-12">
        <ranger-input [rangeFormControl]="form.controls[dimensions[1].name]"
                      [options]="dimensions[1].content[form.controls['flanks'].value - 1]"
                      [steps]="form.controls['grid_unit_depth'].value.value"></ranger-input>
    </div>
</div>

<div class="align-center col-12 col-mobile-landscape-6 col-ipad-portrait-3"
     [hidden]="form.controls['grid_unit_depth'].value.value === true">
  <div class="form-section">
    <span [className]="'form-element__container'">
          <label [className]="'form-element__label'"
                 [attr.for]="'tc-cd-' + chosenDimensions.depth.name"
                 [innerHTML]="'Bitte tragen Sie das gewünschte Fixmaß ein'"></label>
          <input class="input input--white"
                 [min]="dimensions[1].validators[0].minLength"
                 [max]="dimensions[1].validators[1].maxLength"
                 [id]="'tc-cd-' + chosenDimensions.depth.name"
                 [type]="'number'"
                 [value]="form.controls[chosenDimensions.depth.name].value"
                 (change)="checkDimensions(dimensions[1].validators[0].minLength, dimensions[1].validators[1].maxLength, $event, chosenDimensions.depth.name)" />
          <span class="form-element__legend">mm</span>
            <span class="form-icon--edit" [inlineSVG]="'pencil-edit-button.svg'"></span>
      </span>
    <p class="form-section__legend align-center" [innerHTML]="'(min. ' + dimensions[1].content[form.controls['flanks'].value - 1].floor.toLocaleString() + 'mm, max. ' + dimensions[1].content[form.controls['flanks'].value - 1].ceil.toLocaleString() + 'mm.)'"></p>
  </div>
</div>
<h6 class="with-decor" [innerHTML]="heightContent[hcIndex].headline"></h6>
<p class="align-center" [innerHTML]="heightContent[hcIndex].subline"></p>
<div class="tc-chosen-dimensions__height-controls inner-wrap horizontal-center" [formGroup]="form">
    <div [class]="height[0].grid">
        <div class="select-dropdown__container">
            <label class="form-element__label" [innerHTML]="heightContent[hcIndex].firstFloorLabel"></label>
            <ng-select class="select-dropdown select-dropdown--gray"
                       [items]="(form.controls['variant'].value !== '2g' && form.controls['variant'].value !== 'stage') ? [2600, 2850, 3100, 4000] : [2600, 2850, 3100]"
                       [clearable]="false"
                       [formControlName]="height[0].name"
                       (change)="form.controls[height[0].name].setValue(form.controls[height[0].name].value)">
                <ng-template ng-label-tmp let-item="item">
                    {{item.toLocaleString() + ' mm'}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{item.toLocaleString() + ' mm'}}
                </ng-template>
            </ng-select>
        </div>
    </div>

    <div [class]="height[1].grid" [hidden]="(form.controls['variant'].value !== '2g')">
      <div class="select-dropdown__container">
        <label class="form-element__label" [innerHTML]="height[1].label"></label>
        <ng-select class="select-dropdown select-dropdown--gray"
                   [items]="[2600, 2850, 3100]"
                   [clearable]="false"
                   [formControlName]="height[1].name"
                   (change)="form.controls[height[1].name].setValue(form.controls[height[1].name].value)">
          <ng-template ng-label-tmp let-item="item">
            {{item.toLocaleString() + ' mm'}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{item.toLocaleString() + ' mm'}}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div [class]="height[2].grid" [hidden]="(form.controls['variant'].value !== 'stage')">
      <div class="form-section">
        <span [className]="'form-element__container'">
              <label [className]="'form-element__label'"
                     [attr.for]="'tc-cd-' + height[2].name"
                     [innerHTML]="height[2].label"></label>
              <input class="input input--white"
                     [id]="'tc-cd-' + height[2].name"
                     [formControlName]="height[2].name"
                     (change)="updateStageHeight($event.target['valueAsNumber'])"
                     [type]="'number'" />
              <span class="form-element__legend">mm</span>
              <span class="form-icon--edit" [inlineSVG]="'pencil-edit-button.svg'"></span>
          </span>
          <p class="form-section__legend" [innerHTML]="'(min. 500 mm, max. 3.100 mm - Empfohlene Durchgangshöhe mindestens 2.200 mm)'"></p>
      </div>
    </div>
<!--    <ng-container *ngFor="let item of height; let i = index;">-->
<!--        <div [class]="item.grid" *ngIf="i !== 0"-->
<!--             [hidden]="form.controls['variant'].value !== item.depends">-->
<!--            <ranger-input [rangeFormControl]="form.controls[item.name]"-->
<!--                          [options]="item.content"-->
<!--                          [steps]="item.depends === '2g'"></ranger-input>-->
<!--        </div>-->
<!--    </ng-container>-->
</div>
<div class="tc-chosen-dimensions inner-wrap no-gutters" [formGroup]="form">
    <div class="tc-chosen-dimensions__image">
        <img xcoLazyLoad [src]="chosenDimensions.image" alt="" />
    </div>
    <div class="tc-chosen-dimensions__content">
        <div class="flex-helper form-section">
            <div class="col-12">
                <h4 class="" [innerHTML]="chosenDimensions.headline"></h4>
            </div>
            <div class="col-12 col-mobile-landscape-6">
                <span [className]="'form-element__container'">
                    <label [className]="'form-element__label'"
                           [attr.for]="'tc-cd-' + chosenDimensions.length.name"
                           [innerHTML]="chosenDimensions.length.label"></label>
                    <input class="input input--gray"
                           [min]="dimensions[0].validators[0].minLength"
                           [max]="dimensions[0].validators[1].maxLength"
                           [id]="'tc-cd-' + chosenDimensions.length.name"
                           [type]="'number'"
                           [value]="form.controls[chosenDimensions.length.name].value"
                           (change)="checkDimensions(dimensions[0].validators[0].minLength, dimensions[0].validators[1].maxLength, $event, chosenDimensions.length.name)" />
                    <span class="form-element__legend">mm</span>
                    <span class="form-icon--edit" [inlineSVG]="'pencil-edit-button.svg'"></span>
                </span>
            </div>
            <div class="col-12 col-mobile-landscape-6">
                <span [className]="'form-element__container'">
                    <label [className]="'form-element__label'"
                           [attr.for]="'tc-cd-' + chosenDimensions.depth.name"
                           [innerHTML]="chosenDimensions.depth.label"></label>
                    <input class="input input--gray"
                           [min]="dimensions[1].validators[0].minLength"
                           [max]="dimensions[1].validators[1].maxLength"
                           [id]="'tc-cd-' + chosenDimensions.depth.name"
                           [type]="'number'"
                           [value]="form.controls[chosenDimensions.depth.name].value"
                           (change)="checkDimensions(dimensions[1].validators[0].minLength, dimensions[1].validators[1].maxLength, $event, chosenDimensions.depth.name)" />
                    <span class="form-element__legend">mm</span>
                    <span class="form-icon--edit" [inlineSVG]="'pencil-edit-button.svg'"></span>
                </span>
            </div>
            <div class="col-12 col-mobile-landscape-6">
                <div class="form-element__container">
                    <label class="form-element__label" [innerHTML]="heightContent[hcIndex].firstFloorLabel"></label>
                    <ng-select class="select-dropdown select-dropdown--gray"
                               [items]="(form.controls['variant'].value !== '2g' && form.controls['variant'].value !== 'stage') ? [2600, 2850, 3100, 4000] : [2600, 2850, 3100]"
                               [clearable]="false"
                               [formControlName]="height[0].name"
                               (change)="form.controls[height[0].name].setValue(form.controls[height[0].name].value)">
                        <ng-template ng-label-tmp let-item="item">
                            {{item.toLocaleString() + ' mm'}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            {{item.toLocaleString() + ' mm'}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="col-12 col-mobile-landscape-6" *ngIf="form.controls['variant'].value === '2g'">
                <div class="form-element__container">
                    <label class="form-element__label" [innerHTML]="height[1].label"></label>
                    <ng-select class="select-dropdown select-dropdown--gray"
                               [items]="[2600, 2850, 3100]"
                               [clearable]="false"
                               [formControlName]="height[1].name"
                               (change)="form.controls[height[1].name].setValue(form.controls[height[1].name].value)">
                        <ng-template ng-label-tmp let-item="item">
                            {{item.toLocaleString() + ' mm'}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            {{item.toLocaleString() + ' mm'}}
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="col-12 col-mobile-landscape-6" *ngIf="form.controls['variant'].value === 'stage'">
                <span [className]="'form-element__container'">
                    <label [className]="'form-element__label'"
                           [attr.for]="'tc-cd-' + height[2].name"
                           [innerHTML]="height[2].label"></label>
                    <input class="input input--gray"
                           [id]="'tc-cd-' + height[2].name"
                           [type]="'number'"
                           [formControlName]="height[2].name"
                           (change)="updateStageHeight($event.target['valueAsNumber'])" />
                    <span class="form-element__legend">mm</span>
                    <span class="form-icon--edit" [inlineSVG]="'pencil-edit-button.svg'"></span>
                </span>
            </div>
        </div>
    </div>
</div>
