<!--<button class="button" (click)="drawTopView()">Zeichnung zeigen</button>-->
<p *ngIf="this.form.controls['project_name'].value"
   class="align-center"
   (click)="scrollToPdfSection($event)"
   [innerHTML]="'Für das Projekt: ' + form.controls['project_name'].value + ' – Bitte denken Sie daran das vollständige Richtpreisangebot unten auf dieser Seite als <a class=\'tc-reset-button\' href=\'#\'>PDF</a> per E-Mail anzufordern.'">
</p>

<ng-container *ngIf="isDrawn">
  <div class="tc-blueprint__container">
    <div class="tc-blueprint__info">
      <h2>Richtpreis* für ihren neuen Systemraum Trendline:</h2>
      <div class="tc-reporting-price-offer__overall-price" [innerHTML]="'ca. ' + ((price.withoutOptions + price.shipping) | currency: 'EUR')"></div>
      <p>
        <span [innerHTML]="'*zzgl. MwSt., inkl. Fracht und Montage '"></span>
        <span *ngIf="this.product.zipCode" [innerHTML]="'im PLZ-Bereich ' + this.product.zipCode.toString().slice(0, 2)"></span>
      </p>
      <ng-container [ngSwitch]="form.controls['variant'].value">
        <img *ngSwitchCase="'1g'" src="assets/images/tc/variant_dark/1_level.jpg" alt="" />
        <img *ngSwitchCase="'2g'" src="assets/images/tc/variant_dark/2_level.jpg" alt="" />
        <img *ngSwitchCase="'stage'" src="assets/images/tc/variant_dark/on_stage.jpg" alt="" />
        <img *ngSwitchCase="'wallseparation'" src="assets/images/tc/variant_dark/div_wall.jpg" alt="" />
      </ng-container>
<!--      <img src="assets/images/tc/blueprint.jpg" alt="" />-->
    </div>
    <div class="tc-blueprint__blueprints">
      <div class="tc-blueprint__blueprints__disabled-overlay" *ngIf="disableBlueprintContainer">
        <div class="tc-blueprint__disabled-overlay-content">
          <h1>Sie haben die Konfiguration verändert!</h1>
          <p>Die Zeichnung ist veraltet, bitte klicken Sie auf die Schaltfläche "Zeichnung neu generieren"</p>
          <button class="button" (click)="getImages()">Zeichnung neu generieren</button>
        </div>
      </div>
      <div class="tc-blueprint__nav">
        <span class="tc-blueprint__view-link"
              [class.tc-blueprint__view-link--active]="activeView === 1"
              (click)="activeView = 1;">{{product.stage  ? 'Grundriss' : 'Grundriss EG'}}</span>
        <span class="tc-blueprint__view-link"
              *ngIf="product.floors.length === 2"
              [class.tc-blueprint__view-link--active]="activeView === 2"
              (click)="activeView = 2;">Grundriss OG</span>
        <span class="tc-blueprint__view-link"
              [class.tc-blueprint__view-link--active]="activeView === 0"
              (click)="activeView = 0; swiper.directiveRef.update()">Ansichten</span>
        <div class="tc-blueprint__mobile-select select-dropdown__container">
            <ng-select class="select-dropdown select-dropdown--gray"
                       [items]="product.floors.length === 2 ? [ 1, 2, 0] : [1, 0]"
                       [(ngModel)]="activeView"
                       (change)="activeView === 0 ? swiper.directiveRef.update() : false"
                       [clearable]="false">
                <ng-template ng-label-tmp let-item="item">
                  <ng-container [ngSwitch]="item">
                    <span *ngSwitchCase="0" [innerHTML]="'Ansichten'"></span>
                    <span *ngSwitchCase="1" [innerHTML]="product.stage ? 'Grundriss' : 'Grundriss EG'"></span>
                    <span *ngSwitchCase="2" [innerHTML]="'Grundriss OG'"></span>
                  </ng-container>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <ng-container [ngSwitch]="item">
                    <span *ngSwitchCase="0" [innerHTML]="'Ansichten'"></span>
                    <span *ngSwitchCase="1" [innerHTML]="product.stage ? 'Grundriss' : 'Grundriss EG'"></span>
                    <span *ngSwitchCase="2" [innerHTML]="'Grundriss OG'"></span>
                  </ng-container>
              </ng-template>
            </ng-select>
          </div>
      </div>
      <button class="tc-blueprint__zoom-in"
              (click)="overlayImageActive = true; overlayOpened.emit(true)"><span [inlineSVG]="'zoom-in.svg'"></span></button>
      <div class="tc-blueprint__image-container">
        <swiper #swiper
                [swiper]="swiperConfig"
                (indexChange)="currentSideSlide = $event;"
                [hidden]="activeView !== 0">
          <div xcoLazyLoadBackground class="tc-blueprint__image"
               *ngFor="let link of product.blueprintImages.sideView; let i = index;"
               [src]="link['img']">
          </div>
        </swiper>
        <div xcoLazyLoadBackground class="tc-blueprint__image"
             *ngFor="let link of product.blueprintImages.topView; let i = index;"
             [hidden]="activeView !== i + 1"
             [src]="link['img']">
        </div>
      </div>
      <div class="tc-blueprint-slider__navigation tc-blueprint-slider__navigation--centered" *ngIf="activeView === 0">
        <div class="tc-blueprint-slider__arrow tc-blueprint-slider__arrow--prev">
          <div class="svg-icon svg-icon--black"
               [inlineSVG]="'simple-arrow.svg'"
               (click)="swiper.directiveRef.prevSlide()"></div>
        </div>
        <div class="current"
             [innerHTML]="currentSideSlide + 1"></div>
        <div class="total"
             [innerHTML]="product.blueprintImages.sideView.length"></div>
        <div class="tc-blueprint-slider__arrow tc-blueprint-slider__arrow--next">
          <div class="svg-icon svg-icon--black"
               [inlineSVG]="'simple-arrow.svg'"
               (click)="swiper.directiveRef.nextSlide()"></div>
        </div>
      </div>
      <div class="tc-blueprint-slider__navigation tc-blueprint-slider__navigation--centered" *ngIf="activeView !== 0">
        <div class="tc-blueprint-slider__arrow tc-blueprint-slider__arrow--prev">
          <div class="svg-icon svg-icon--black"
               [inlineSVG]="'simple-arrow.svg'"></div>
        </div>
        <div class="current"
             [innerHTML]="1"></div>
        <div class="total"
             [innerHTML]="1"></div>
        <div class="tc-blueprint-slider__arrow tc-blueprint-slider__arrow--next">
          <div class="svg-icon svg-icon--black"
               [inlineSVG]="'simple-arrow.svg'"></div>
        </div>
      </div>
<!--      <div class="tc-blueprint__label" *ngIf="activeView === 0" [innerHTML]="'Ansicht W-' + (currentSideSlide + 1)"></div>-->
<!--      <div class="tc-blueprint__label" *ngIf="activeView !== 0" [innerHTML]="(activeView === 1) ? 'Ansicht Erdgeschoß' : 'Ansicht Obergeschoß'"></div>-->
    </div>
  </div>
</ng-container>
<app-overlay [image]="getOverlayImage()"
             [fullWidth]="true"
             [bgColor]="'grey'"
             (toggleClose)="overlayImageActive = false; overlayOpened.emit(false)"
             *ngIf="overlayImage && overlayImageActive"></app-overlay>
<canvas [hidden]="true"
        id="canvas"
        width="100%"
        height="auto"
></canvas>

