import {AfterViewInit, Component, ContentChild, Input, OnChanges, OnInit, TemplateRef} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TrendlineConfiguratorRoomDivisionSelectDirective} from './trendline-configurator-room-division.directive';
import { TrendlineConfiguratorProduct } from '../../trendline-configurator.model';

@Component({
  selector: 'tc-room-division',
  templateUrl: './trendline-configurator-room-division.component.html',
  styles: []
})
export class TrendlineConfiguratorRoomDivisionComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @ContentChild(TrendlineConfiguratorRoomDivisionSelectDirective, { static: false, read: TemplateRef }) selectTemplate: TemplateRef<any>;
  data = {
    subline: ['Bitte geben Sie die gewünschte Raumaufteilung an', 'Bitte geben Sie die gewünschte Raumaufteilung für die einzelnen Stockwerke an'],
    roomLabel: 'Anzahl der Räume',
    roomObject: [
      {
        type: 'select',
        grid: 'col-12 col-ipad-landscape-4',
        label: ' im Erdgeschoß',
        name: 'room_number_ff',
        img: 'assets/images/tc/room/floor-first.jpg',
        validators: [],
        value: {value: 1, label: '1 Raum'},
        content: null
      },
      {
        type: 'select',
        grid: 'col-12 col-ipad-landscape-4',
        label: ' im Obergeschoß',
        name: 'room_number_sf',
        img: 'assets/images/tc/room/floor-second.jpg',
        validators: [],
        value: {value: 1, label: '1 Raum'},
        content: null
      }
    ]
  };
  constructor(private _product: TrendlineConfiguratorProduct) {}

  ngOnInit() {
    this.data.roomObject.forEach(data => this.form.addControl(data.name, new FormControl(data.value)));
    this.buildContentArray();
  }

  ngAfterViewInit() {
    this.form.controls['length'].valueChanges.subscribe(() => {
      this.buildContentArray();
    });
  }

  buildContentArray() {
    let i;
    const content = [];
    const single = 'Raum'; const multiple = 'Räume';
    for (i = 1; i <= this._product.maxRoom; i++) {
      content.push({
        value: i,
        label: i + ' ' + ((i === 1) ? single : multiple)
      });
    }

    this.data.roomObject.forEach(data => {
      data.content = content;
      if (this.form.controls[data.name].value !== null && this.form.controls[data.name].value.value > this._product.maxRoom) {
        this.form.controls[data.name].setValue(content[this._product.maxRoom - 1]);
      } else if (this.form.controls[data.name].value !== null && this.form.controls[data.name].value.value <= this._product.maxRoom) {
        return;
      } else {
        this.form.controls[data.name].setValue(content[0]);
      }
    });
  }

}
