import { Injectable } from '@angular/core';
import {TrendlineConfiguratorProduct} from '../trendline-configurator.model';

@Injectable({providedIn: 'root'})
export class TrendlineConfiguratorBlueprintCalculationService {
  drawingObject: any;
  constructor(
    private product: TrendlineConfiguratorProduct,
  ) {}

  getDrawingObject() {
    const solidWallConnectLengthElement = this.product.outerFillerLength === 0 ? {name: 'solidWall'} : {name: 'solidFillerWall', width: this.product.outerFillerLength};
    const solidWallConnectLengthElementAmount = this.product.outerFillerLength === 0 ? 1 : 0;
    const solidWallConnectDepthElement = this.product.outerFillerDepth === 0 ? {name: 'solidWall'} : {name: 'solidFillerWall', width: this.product.outerFillerDepth};
    const solidWallConnectDepthElementAmount = this.product.outerFillerDepth === 0 ? 1 : 0;
    switch (this.product.flanks) {
      case 1:
        this.drawingObject = {
          east: [{name: 'existingWall'}],
          south: [{name: 'existingWall'}],
          west: [
            {name: 'wallConnectionProfileReversed'},
            solidWallConnectLengthElement,
            {
              name: 'glassWall',
              amount: this.calcGridWallAmount(
                'length',
                this.product.wallConnectionProfileUnit * 2) - 1 - solidWallConnectLengthElementAmount
            },
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'outerDoor'},
            {name: 'wallConnectionProfile'},
          ],
          north: [{name: 'existingWall'}]
        };
        break;
      case 2:
        this.drawingObject = {
          east: [{name: 'existingWall'}],
          south: [{name: 'existingWall'}],
          west: [
            {name: 'wallConnectionProfileReversed'},
            solidWallConnectLengthElement,
            {
              name: 'glassWall',
              amount: this.calcGridWallAmount(
                'length',
                this.product.wallConnectionProfileUnit * 2) - solidWallConnectLengthElementAmount
            },
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'cornerProfile'}
          ],
          north: [
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', this.product.wallConnectionProfileUnit + this.product.cornerProfileUnit) - 1},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'outerDoor'},
            {name: 'wallConnectionProfile'}
          ]
        };
        break;
      case 3:
        this.drawingObject = {
          east: [{name: 'existingWall'}],
          south: [
            {name: 'wallConnectionProfileReversed'},
            solidWallConnectDepthElement,
            {
              name: 'glassWall',
              amount: this.calcGridWallAmount(
                'depth',
                this.product.wallConnectionProfileUnit + this.product.cornerProfileUnit) - solidWallConnectDepthElementAmount
            },
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'cornerProfile'}
          ],
          west: [
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'glassWall', amount: this.calcGridWallAmount('length', this.product.cornerProfileUnit * 2)},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'cornerProfile'}
          ],
          north: [
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', this.product.wallConnectionProfileUnit + this.product.cornerProfileUnit) - 1},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'outerDoor'},
            {name: 'wallConnectionProfile'}
          ]
        };
        break;
      case 4:
        this.drawingObject = {
          east: [
            {name: 'cornerProfile'},
            {name: 'solidFillerWall', width: this.product.outerFillerLength},
            {name: 'solidWall', amount: this.calcGridWallAmount('length', this.product.cornerProfileUnit * 2)},
            {name: 'solidFillerWall', width: this.product.outerFillerLength},
            {name: 'cornerProfile'},
          ],
          south: [
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', this.product.cornerProfileUnit * 2)},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'cornerProfile'}
          ],
          west: [
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'glassWall', amount: this.calcGridWallAmount('length', this.product.cornerProfileUnit * 2)},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'cornerProfile'}
          ],
          north: [
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', 2 * this.product.cornerProfileUnit) - 1},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'outerDoor'}
          ]
        };
        break;
    }
    return this.drawingObject;
  }

  getSideDrawingObject() {
    const solidWallConnectLengthElement = this.product.outerFillerLength === 0 ? {name: 'solidWall'} : {name: 'solidFillerWall', width: this.product.outerFillerLength};
    const solidWallConnectLengthElementAmount = this.product.outerFillerLength === 0 ? 1 : 0;
    const solidWallConnectDepthElement = this.product.outerFillerDepth === 0 ? {name: 'solidWall'} : {name: 'solidFillerWall', width: this.product.outerFillerDepth};
    const solidWallConnectDepthElementAmount = this.product.outerFillerDepth === 0 ? 1 : 0;
    switch (this.product.flanks) {
      case 1:
        this.drawingObject = {
          west: [
            {name: 'wallConnectionProfile'},
            {name: 'outerDoor'},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {
              name: 'glassWall',
              amount: this.calcGridWallAmount(
                'length',
                this.product.wallConnectionProfileUnit * 2) - 1 - solidWallConnectLengthElementAmount
            },
            solidWallConnectLengthElement,
            {name: 'wallConnectionProfile'}
          ],
          north: [{name: 'existingWall'}],
          east: [{name: 'existingWall'}],
          south: [{name: 'existingWall'}]
        };
        break;
      case 2:
        this.drawingObject = {
          west: [
            {name: 'cornerProfile'},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {
              name: 'glassWall',
              amount: this.calcGridWallAmount(
                'length',
                this.product.wallConnectionProfileUnit * 2) - solidWallConnectLengthElementAmount
            },
            solidWallConnectLengthElement,
            {name: 'wallConnectionProfile'}
          ],
          north: [
            {name: 'outerDoor'},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', this.product.wallConnectionProfileUnit + this.product.cornerProfileUnit) - 1},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'wallConnectionProfile'}
          ],
          east: [{name: 'existingWall'}],
          south: [{name: 'existingWall'}],
        };
        break;
      case 3:
        this.drawingObject = {
          west: [
            {name: 'cornerProfile'},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'glassWall', amount: this.calcGridWallAmount('length', this.product.cornerProfileUnit * 2)},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'wallConnectionProfile'}
          ],
          north: [
            {name: 'outerDoor'},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', this.product.wallConnectionProfileUnit + this.product.cornerProfileUnit) - 1},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'wallConnectionProfile'}
          ],
          east: [{name: 'existingWall'}],
          south: [
            {name: 'cornerProfile'},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {
              name: 'glassWall',
              amount: this.calcGridWallAmount(
                'depth',
                this.product.wallConnectionProfileUnit + this.product.cornerProfileUnit) - solidWallConnectDepthElementAmount
            },
            solidWallConnectDepthElement,
            {name: 'wallConnectionProfile'}
          ]
        };
        break;
      case 4:
        this.drawingObject = {
          west: [
            {name: 'cornerProfile'},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'glassWall', amount: this.calcGridWallAmount('length', this.product.cornerProfileUnit * 2)},
            {name: 'glassFillerWall', width: this.product.outerFillerLength},
            {name: 'cornerProfile'}
          ],
          north: [
            {name: 'cornerProfile'},
            {name: 'outerDoor'},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', 2 * this.product.cornerProfileUnit) - 1},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'cornerProfile'},
          ],
          east: [
            {name: 'cornerProfile'},
            {name: 'solidFillerWall', width: this.product.outerFillerLength},
            {name: 'solidWall', amount: this.calcGridWallAmount('length', this.product.cornerProfileUnit * 2)},
            {name: 'solidFillerWall', width: this.product.outerFillerLength},
            {name: 'cornerProfile'},
          ],
          south: [
            {name: 'cornerProfile'},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'glassWall', amount: this.calcGridWallAmount('depth', this.product.cornerProfileUnit * 2)},
            {name: 'glassFillerWall', width: this.product.outerFillerDepth},
            {name: 'cornerProfile'}
          ]
        };
        break;
    }
    return this.drawingObject;
  }

  calcGridWallAmount(side: string, unitsSize: number) {
    if (side === 'inner') {
      return Math.round((this.product.depth - unitsSize - (this.product.innerFillersize * 2)) / this.product.gridUnit) - 1;
    } else {
      return Math.round((this.product[side] - unitsSize - (this.product['outerFiller' + side.charAt(0).toUpperCase() + side.slice(1)] * 2)) / this.product.gridUnit);
    }
  }
}
