import {Component, OnInit, Input, ContentChild, TemplateRef, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  TrendlineConfiguratorSectionButtonIconDirective,
  TrendlineConfiguratorSectionSelectDirective,
  TrendlineConfiguratorSectionCheckboxIconDirective,
  TrendlineConfiguratorSectionRadioIconDirective,
  TrendlineConfiguratorSectionRangeDirective
} from './trendline-configurator-section.directive';

@Component({
  selector: 'trendline-configurator-section',
  templateUrl: './trendline-configurator-section.component.html',
  styles: []
})
export class TrendlineConfiguratorSectionComponent implements OnInit {
  @Input() data;
  @Input() form: FormGroup;
  @Output() toggleClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() infoContent: EventEmitter<string> = new EventEmitter<string>();
  @ContentChild(TrendlineConfiguratorSectionButtonIconDirective, { static: false, read: TemplateRef }) btnIconTemplate: TemplateRef<any>;
  @ContentChild(TrendlineConfiguratorSectionSelectDirective, { static: false, read: TemplateRef }) selectTemplate: TemplateRef<any>;
  @ContentChild(TrendlineConfiguratorSectionCheckboxIconDirective, { static: false, read: TemplateRef }) checkboxIconTemplate: TemplateRef<any>;
  @ContentChild(TrendlineConfiguratorSectionRadioIconDirective, { static: false, read: TemplateRef }) radioIconTemplate: TemplateRef<any>;
  @ContentChild(TrendlineConfiguratorSectionRangeDirective, { static: false, read: TemplateRef }) rangeTemplate: TemplateRef<any>;
  sectionId;
  constructor() { }

  ngOnInit() {
    this.sectionId = Math.random().toString(36).substr(2, 9);
  }
}
