import { Injectable } from '@angular/core';
import {TrendlineConfiguratorProduct} from '../trendline-configurator.model';
import {fabric} from 'fabric';
import {TrendlineConfiguratorBlueprintCalculationService} from './trendline-configurator-blueprint-calculation.service';

@Injectable()
export class TrendlineConfiguratorBlueprintTopViewService {
  canvas: any;
  innerCanvas;
  startPoint: any;
  elements: any;
  drawingObject: any;
  multFactor: number;
  fontSize: number;
  canvasObjectsWrapper: any;
  imgLinks: Array<any>;
  constructor(
    private blueprintCalculation: TrendlineConfiguratorBlueprintCalculationService,
    private product: TrendlineConfiguratorProduct,
  ) {}

  generateImages(canvas, svgElements) {
    this.imgLinks = [];
    this.canvas = canvas;
    this.elements = svgElements;
    this.product.floors.forEach((floor, index) => {
      switch (index) {
        case 0:
          if (this.product.stage) {
            this.imgLinks.push({
              'name': 'stage',
              'img': this.draw(floor, index)
            });
          } else {
            this.imgLinks.push({
              'name': 'firstFloor',
              'img': this.draw(floor, index)
            });
          }
          break;
        case 1:
          this.imgLinks.push({
            'name' : 'secondFloor',
            'img': this.draw(floor, index)
          });
      }
    });
    return this.imgLinks;
  }

  private draw(floor, index: number) {
    this.canvas.clear();
    this.setCanvas();
    this.drawingObject = this.blueprintCalculation.getDrawingObject();
    this.drawInnerCanvas();
    for (const wall in this.drawingObject) {
      if (this.drawingObject.hasOwnProperty(wall)) {
        this.drawGroup(this.drawingObject[wall], wall);
      }
    }

    if (floor.roomAmount > 1 ) {
      this.drawInnerWalls(floor);
    }

    if (this.product.stage) {
      this.setStageStairs();
    }

    if (this.product.floorsLength === 2) {
      const stairsObjName = (index === 1) ? 'Top' : 'Bottom';
      this.setStandardStairs('stairs' + stairsObjName + this.product.floors[0].floorHeight);
    }

    this.setLegends();
    this.fixCanvasDimensions();
    return this.canvas.toDataURL({
        format: 'png',
        quality: 1,
        enableRetinaScaling: true
      });
  }
  private setStageStairs() {
    const stageStairs = new fabric.Group();
    const podest = this.getSvgObj(this.elements.stairsPodest.svg);
    const middle = this.getSvgObj('stairsMiddle');
    const feet = this.getSvgObj(this.elements.stairsFeet.svg);
    stageStairs.addWithUpdate(podest.set({top: 0, left: 0}));
    for (let i = 1; i <= this.product.stairwayData.amount; i++) {
      if (i !== this.product.stairwayData.amount) {
        stageStairs.addWithUpdate(this.getSvgObj(this.elements.stairsMiddle.svg).set({top: stageStairs.aCoords.bl.y, left: stageStairs.aCoords.bl.x}));
      } else {
        stageStairs.addWithUpdate(this.getSvgObj(this.elements.stairsFeet.svg).set({top: stageStairs.aCoords.bl.y, left: stageStairs.aCoords.bl.x}));
      }
    }
    stageStairs.scale(this.multFactor);
    this.canvas.add(stageStairs);
    this.setStairs(stageStairs);

    if (this.product.flanks === 1) {
      stageStairs.set({
        top: this.innerCanvas.obj.aCoords.bl.y + (stageStairs.getScaledWidth()),
        left: this.innerCanvas.obj.aCoords.bl.x,
        angle: 270
      });
    } else {
      stageStairs.set({
        top: this.innerCanvas.obj.aCoords.tl.y,
        left: this.innerCanvas.obj.aCoords.tl.x - (stageStairs.getScaledWidth())
      });
    }
  }

  private setStandardStairs(stairsObjName) {
    this.drawSingleSvg(stairsObjName, false, {});
  }

  private fixCanvasDimensions() {
    this.canvasObjectsWrapper = new fabric.Group();
    this.canvas.forEachObject(elm => this.canvasObjectsWrapper.addWithUpdate(elm) );
    this.canvasObjectsWrapper.scale(.75);
    this.canvas.set({
      width: this.canvasObjectsWrapper.getScaledWidth() + 150,
      height: this.canvasObjectsWrapper.getScaledHeight() + 150,
    });

    this.canvasObjectsWrapper.originX = 'center';
    this.canvasObjectsWrapper.originY = 'center';
    this.canvasObjectsWrapper.centeredScaling = true;
    this.canvas.viewportCenterObject(this.canvasObjectsWrapper);
  }

  private drawGroup(group, direction: string) {
    group.forEach((elm, index) => {
      let options: object;
      let amount = 1;

      if (elm.width === 0) { return; }

      if (elm.hasOwnProperty('width') ) {
        options = { width: elm.width, angle: this.setDirection(direction) };
      } else {
        options = { angle: this.setDirection(direction)};
      }

      if (elm.hasOwnProperty('amount')) {
        amount = elm.amount;
      }

      for (let i = 0; i < amount; i++) {
        options['id'] = `${index}${i}-${direction}-${elm.name}`;
        this.drawSingleSvg(elm.name, index === (group.length - 1), options);
      }
    });
  }

  private setLegends() {
    this.fontSize = 50 * (this.multFactor + .5);
    const offsetLeft = this.product.gridUnit * 1.2 * this.multFactor;
    const offsetBottom = this.product.gridUnit * ((this.product.flanks === 1) ? 1 : .7) * this.multFactor;
    const offsetViewLegend = this.product.gridUnit * (this.product.length / 25000) * this.multFactor;
    const legendStartPointOffset = this.product.gridUnit * .2 * this.multFactor;
    const depthLegend = new fabric.Text(this.product.depth.toLocaleString(), {
      angle: 270,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    const lengthLegend = new fabric.Text(this.product.length.toLocaleString(), {
      angle: 0,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    const viewALegend = new fabric.Text('Ansicht A', {
      angle: 0,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    const viewBLegend = new fabric.Text('Ansicht B', {
      angle: 0,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    const viewCLegend = new fabric.Text('Ansicht C', {
      angle: 0,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    const viewDLegend = new fabric.Text('Ansicht D', {
      angle: 0,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    const arrowGroupLeft = new fabric.Group([
      new fabric.Rect({
        height: 1,
        width: offsetLeft - legendStartPointOffset,
        angle: 180,
        fill: '#0FFF00',
        left: this.innerCanvas.obj.aCoords.tl.x - legendStartPointOffset,
        top: this.innerCanvas.obj.aCoords.tl.y + 1,
      }),
      new fabric.Triangle({
        width: 10,
        height: 15,
        fill: '#0FFF00',
        left: this.innerCanvas.obj.aCoords.tl.x - offsetLeft,
        top: this.innerCanvas.obj.aCoords.tl.y,
        angle: 0,
        // originY: 'center',
        originX: 'center'
      }),
      new fabric.Rect({
        width: 1,
        height: this.innerCanvas.obj.getScaledHeight(),
        left: this.innerCanvas.obj.aCoords.tl.x - offsetLeft,
        top: this.innerCanvas.obj.aCoords.tl.y,
        fill: '#0FFF00',
        originX: 'center',
        angle: 0
      }),
      new fabric.Triangle({
        width: 10,
        height: 15,
        fill: '#0FFF00',
        left: this.innerCanvas.obj.aCoords.bl.x - offsetLeft,
        top: this.innerCanvas.obj.aCoords.bl.y + 1,
        angle: 180,
        // originY: 'center',
        originX: 'center'
      }),
      new fabric.Rect({
        height: 1,
        width: offsetLeft - legendStartPointOffset,
        angle: 180,
        fill: '#0FFF00',
        left: this.innerCanvas.obj.aCoords.bl.x - legendStartPointOffset,
        top: this.innerCanvas.obj.aCoords.bl.y
      })
    ]);
    const arrowGroupBottom = new fabric.Group([
      new fabric.Rect({
        height: 1,
        width: offsetBottom - legendStartPointOffset,
        angle: 90,
        fill: '#0FFF00',
        originY: 'center',
        left: this.innerCanvas.obj.aCoords.bl.x,
        top: this.innerCanvas.obj.aCoords.bl.y + legendStartPointOffset,
      }),
      new fabric.Triangle({
        width: 10,
        height: 15,
        fill: '#0FFF00',
        left: this.innerCanvas.obj.aCoords.bl.x - 1,
        top: this.innerCanvas.obj.aCoords.bl.y + offsetBottom,
        angle: 270,
        originX: 'center'
      }),
      new fabric.Rect({
        height: 1,
        width: this.innerCanvas.obj.getScaledWidth(),
        left: this.innerCanvas.obj.aCoords.bl.x,
        top: this.innerCanvas.obj.aCoords.bl.y + offsetBottom,
        fill: '#0FFF00',
        originY: 'center',
        angle: 0
      }),
      new fabric.Triangle({
        width: 10,
        height: 15,
        fill: '#0FFF00',
        left: this.innerCanvas.obj.aCoords.br.x,
        top: this.innerCanvas.obj.aCoords.br.y + offsetBottom,
        angle: 90,
        originX: 'center'
      }),
      new fabric.Rect({
        height: 1,
        width: offsetBottom - legendStartPointOffset,
        angle: 90,
        fill: '#0FFF00',
        left: this.innerCanvas.obj.aCoords.br.x,
        top: this.innerCanvas.obj.aCoords.br.y + legendStartPointOffset,
      })
    ]);

    this.canvas.add(arrowGroupBottom);
    this.canvas.add(lengthLegend.set({
      left: this.innerCanvas.obj.aCoords.bl.x + (this.innerCanvas.obj.getScaledWidth() / 2),
      top: this.innerCanvas.obj.aCoords.bl.y + offsetBottom + lengthLegend.getScaledHeight() / 2
    }));

    this.canvas.add(arrowGroupLeft);
    this.canvas.add(depthLegend.set({
      left: this.innerCanvas.obj.aCoords.tl.x - offsetLeft - depthLegend.getScaledHeight(),
      top: this.innerCanvas.obj.aCoords.tl.y + (this.innerCanvas.obj.getScaledHeight() / 2)
    }));

    switch (this.product.flanks) {
      case 4:
        this.canvas.add(viewALegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x + (this.innerCanvas.obj.getScaledWidth() / 2),
          top: this.innerCanvas.obj.aCoords.bl.y + offsetBottom + offsetViewLegend + viewALegend.getScaledHeight()
        }));
        this.canvas.add(viewBLegend.set({
          left: this.innerCanvas.obj.aCoords.tr.x + viewBLegend.getScaledWidth() / 2 + offsetViewLegend,
          top: this.innerCanvas.obj.aCoords.tr.y  + (this.innerCanvas.obj.getScaledHeight() / 2)
        }));
        this.canvas.add(viewCLegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x + (this.innerCanvas.obj.getScaledWidth() / 2),
          top: this.innerCanvas.obj.aCoords.tl.y - viewCLegend.getScaledHeight() / 2 - offsetViewLegend
        }));
        this.canvas.add(viewDLegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x - offsetLeft - viewDLegend.getScaledWidth() / 1.5 - offsetViewLegend,
          top: this.innerCanvas.obj.aCoords.tr.y  + (this.innerCanvas.obj.getScaledHeight() / 2)
        }));
        break;
      case 3:
        this.canvas.add(viewALegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x + (this.innerCanvas.obj.getScaledWidth() / 2),
          top: this.innerCanvas.obj.aCoords.bl.y + offsetBottom + offsetViewLegend + viewALegend.getScaledHeight()
        }));
        this.canvas.add(viewBLegend.set({
          left: this.innerCanvas.obj.aCoords.tr.x + viewBLegend.getScaledWidth() / 2 + offsetViewLegend + 100,
          top: this.innerCanvas.obj.aCoords.tr.y  + (this.innerCanvas.obj.getScaledHeight() / 2)
        }));
        this.canvas.add(viewCLegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x - offsetLeft - viewCLegend.getScaledWidth() / 1.5 - offsetViewLegend,
          top: this.innerCanvas.obj.aCoords.tr.y  + (this.innerCanvas.obj.getScaledHeight() / 2)
        }));
        break;
      case 2:
        this.canvas.add(viewALegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x + (this.innerCanvas.obj.getScaledWidth() / 2),
          top: this.innerCanvas.obj.aCoords.bl.y + offsetBottom + offsetViewLegend + viewALegend.getScaledHeight()
        }));
        this.canvas.add(viewBLegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x - offsetLeft - viewBLegend.getScaledWidth() / 1.5 - offsetViewLegend,
          top: this.innerCanvas.obj.aCoords.tr.y  + (this.innerCanvas.obj.getScaledHeight() / 2)
        }));
        break;
      case 1:
        this.canvas.add(viewALegend.set({
          left: this.innerCanvas.obj.aCoords.tl.x + (this.innerCanvas.obj.getScaledWidth() / 2),
          top: this.innerCanvas.obj.aCoords.bl.y + offsetBottom + offsetViewLegend + viewALegend.getScaledHeight()
        }));
        break;
    }

  }

  private setStairs(obj) {
    if (this.product.flanks === 1) {
      obj.set({
        top: this.innerCanvas.obj.aCoords.bl.y + (obj.getScaledWidth() * this.multFactor),
        left: this.innerCanvas.obj.aCoords.bl.x,
        angle: 270
      });
    } else {
      obj.set({
        top: this.innerCanvas.obj.aCoords.tl.y,
        left: this.innerCanvas.obj.aCoords.tl.x - (obj.getScaledWidth() * this.multFactor)
      });
    }
  }

  private getSvgObj(elem) {
    const objArray = [];
    fabric.loadSVGFromString(elem, (results) => {
      objArray.push(fabric.util.groupSVGElements(results));
    });
    return objArray[0];
  }

  private drawInnerWalls(floor) {
    const westSide = [];
    const regex = /.*(west(.)*(door(.*)|wall(.*))+)+.*/gmi;
    this.canvas.forEachObject((obj) => {
      if (obj.id && obj.id.match(regex)) {
        if (!obj.id.match('Filler') && !obj.id.match('wallConnectionProfile')) {
          westSide.push(obj);
        }
      }
    });

    let next = 0;
    floor.innerRoomGridUnitsLength.forEach( num => {
      next += num;
      if (next === westSide.length ) { return; }
      const divPoint = westSide[next - 1];
      this.startPoint = {
        left: divPoint.aCoords.br.x - (divPoint.getScaledHeight() / 2),
        top: divPoint.aCoords.br.y,
      };

      this.drawGroup(this.setInnerWallObject(), 'north');
    });
  }

  private setInnerWallObject () {
    const amount = this.blueprintCalculation.calcGridWallAmount('inner', (2 * this.product.cornerProfileUnit) + (2 * this.product.wallConnectionProfileUnit));
    const innerUnitWallAmount = amount === 0 ? {name: 'solidFillerWall', width: 0} : {name: 'solidWall', amount: amount};
    const normalCase = [
      {name: 'wallConnectionProfileReversed'},
      {name: 'solidFillerWall', width: this.product.innerFillersize},
      innerUnitWallAmount,
      {name: 'solidFillerWall', width: amount === -1 ? 0 : this.product.innerFillersize},
      {name: 'innerDoor'},
      {name: 'wallConnectionProfile'},
    ];
    const wallSeparation =  [
      {name: 'wallConnectionProfileReversed'},
      {name: 'separateWallDoor'},
      {name: 'solidFillerWall', width: amount === -1 ? 0 : this.product.innerFillersize},
      innerUnitWallAmount,
      {name: 'solidFillerWall', width: this.product.innerFillersize},
      {name: 'wallConnectionProfile'},
    ];

    return (this.product.roof) ? normalCase : wallSeparation;
  }

  private drawSingleSvg(elm, last: boolean, options) {
    const elem = this.elements[elm].svg;
    const that = this;
    fabric.loadSVGFromString(elem, (results) => {
      const obj = fabric.util.groupSVGElements(results);
      obj.set(options);
      obj.set({left: that.startPoint.left, top: that.startPoint.top});
      switch (elm) {
        case 'outerDoor':
          this.setOuterDoor(obj);
          break;
        case 'innerDoor':
          this.setInnerDoor(obj);
          break;
        case 'separateWallDoor':
          this.setSeparateWallDoor(obj);
          break;
        // case 'stairsTop':
        //   this.setStairs(obj);
      }

      if (elm.match('stairsTop') || elm.match('stairsBottom')) {
        this.setStairs(obj);
      }
      obj.scale(this.multFactor);
      that.canvas.add(obj);
      that.shiftStartPoint(obj, last);
    });
  }

  private setDirection(dir: string) {
    return  {east: 0, south: 90, west: 180, north: 270}[dir];
  }

  private setOuterDoor(obj) {
    switch (obj.angle) {
      case 180:
        obj.set({top: this.startPoint.top + ((obj.getScaledHeight() - this.product.cornerProfileUnit) * this.multFactor) });
        break;
      case 270:
        obj.set({left: this.startPoint.left - ((obj.getScaledHeight() - this.product.cornerProfileUnit) * this.multFactor)});
        break;
    }
  }

  private setInnerDoor(obj) {
    obj.set({left: this.startPoint.left});
  }

  private setSeparateWallDoor(obj) {
    obj.set({left: this.startPoint.left - ((obj.getScaledHeight() - this.product.cornerProfileUnit) * this.multFactor) });
  }

  private shiftStartPoint(obj, last?: boolean) {
    switch (obj.angle) {
      case 0:
        if (obj.id && obj.id.match('existing')) {
          this.setExistingWalls(obj);
        } else {
          this.startPoint.left += obj.getScaledWidth();
        }
        this.startPoint.top += last ? obj.getScaledHeight() : 0;
        break;
      case 90:
        if (obj.id && obj.id.match('existing')) {
          this.setExistingWalls(obj);
        } else {
          this.startPoint.top += obj.getScaledWidth();
        }
        this.startPoint.left += last ? -(obj.getScaledHeight()) : 0;
        break;
      case 180:
        this.startPoint.left += -(obj.getScaledWidth());
        this.startPoint.top += last ? -(obj.getScaledHeight()) : 0;
        break;
      case 270:
        if (obj.id && obj.id.match('existing')) {
          this.setExistingWalls(obj);
        } else {
          this.startPoint.top += -(obj.getScaledWidth());
        }
        break;
    }
  }

  private drawCleaderAngle(coordinates) {
    return  new fabric.Line(coordinates, {
      strokeDashArray: [5, 5],
      stroke: '#000'
    });
  }

  private drawLegend(string, fontSize) {
    return new fabric.Text(string, {
      fontFamily: 'Arial',
      fontSize: fontSize,
      originX: 'center',
      originY: 'center'
    });
  }

  private setExistingWalls(obj) {
    let cleaderAngledashed;
    const innerCanvas = this.innerCanvas.obj;
    const existingWallLegend = this.drawLegend('Bestandswand', obj.getScaledHeight() * .8);
    const cleaderAngleLegend = this.drawLegend('Verkleidungswinkel', obj.getScaledHeight() * .5);

    switch (obj.angle) {
      case 0:
        obj.set({
          width: this.product.length + (this.product.existingWallHeight * 2),
          left: innerCanvas.aCoords.tl.x - obj.getScaledHeight(),
          top: innerCanvas.aCoords.tl.y - obj.getScaledHeight()
        });
        cleaderAngledashed = this.drawCleaderAngle([
          innerCanvas.aCoords.tl.x,
          innerCanvas.aCoords.tl.y + obj.getScaledHeight() * .4,
          innerCanvas.aCoords.tr.x,
          innerCanvas.aCoords.tr.y + obj.getScaledHeight() * .4
        ]);
        this.canvas.add(existingWallLegend.set({
            left: obj.aCoords.tl.x - obj.getScaledHeight() + obj.getScaledWidth() / 2,
            top: obj.aCoords.tl.y - obj.getScaledHeight() / 2
          }));

        if (this.product.roof) {
          this.canvas.add(
            new fabric.Group([
              cleaderAngledashed,
              cleaderAngleLegend.set({
                top: cleaderAngledashed.y1 + cleaderAngleLegend.getScaledHeight(),
                left: cleaderAngledashed.x1 + (cleaderAngledashed.width / 2)
              })
            ])
          );
        }
        this.startPoint.left = innerCanvas.aCoords.tr.x;
        this.startPoint.top += -(obj.getScaledHeight());
        break;
      case 90:
        obj.set({
          width: this.product.depth + obj.height,
          top: innerCanvas.aCoords.tr.y - obj.strokeWidth,
          left: innerCanvas.aCoords.tr.x + obj.getScaledHeight() - obj.strokeWidth
        });

        cleaderAngledashed = this.drawCleaderAngle([
          innerCanvas.aCoords.tr.x - obj.getScaledHeight() * .4,
          innerCanvas.aCoords.tr.y,
          innerCanvas.aCoords.br.x - obj.getScaledHeight() * .4,
          innerCanvas.aCoords.br.y
        ]);

        this.canvas.add(
          existingWallLegend.set({
            left: obj.aCoords.tl.x + obj.getScaledHeight() / 2,
            top: obj.aCoords.tl.y - (obj.getScaledHeight() / 2) + obj.getScaledWidth() / 2,
            angle: 270
          })
        );

        if (this.product.roof) {
          this.canvas.add(
            new fabric.Group([
              cleaderAngledashed,
              cleaderAngleLegend.set({
                top: cleaderAngledashed.y2 - (cleaderAngledashed.height / 2),
                left: cleaderAngledashed.x1 - cleaderAngleLegend.getScaledHeight(),
                angle: 270
              })
            ])
          );
        }

        this.startPoint.top = innerCanvas.aCoords.br.y;
        this.startPoint.left += obj.getScaledHeight();
        break;
      case 270:
        obj.set({
          width: this.product.depth + obj.height,
          top: innerCanvas.aCoords.bl.y + (obj.getScaledHeight() - obj.strokeWidth * 2),
          left: innerCanvas.aCoords.tl.x - obj.getScaledHeight()
        });

        cleaderAngledashed = this.drawCleaderAngle([
          innerCanvas.aCoords.tl.x + obj.getScaledHeight() * .4,
          innerCanvas.aCoords.tl.y,
          innerCanvas.aCoords.bl.x + obj.getScaledHeight() * .4,
          innerCanvas.aCoords.bl.y
        ]);

        this.canvas.add(
          existingWallLegend.set({
            left: obj.aCoords.tr.x - obj.getScaledHeight() / 2,
            top: obj.aCoords.tl.y + (obj.getScaledHeight() / 2) - obj.getScaledWidth() / 2,
            angle: 270
          })
        );

        if (this.product.roof) {
          this.canvas.add(
            new fabric.Group([
              cleaderAngledashed,
              cleaderAngleLegend.set({
                top: cleaderAngledashed.y2 - (cleaderAngledashed.height / 2),
                left: cleaderAngledashed.x1 + cleaderAngleLegend.getScaledHeight(),
                angle: 270
              })
            ])
          );
        }

        this.startPoint.left = innerCanvas.aCoords.tl.x;
        this.startPoint.top = innerCanvas.aCoords.tl.y;
        break;
    }
  }

  private setCanvas() {
    this.canvas.setWidth(2000);
    this.canvas.setHeight((2000 * this.product.depth) / this.product.length);

    this.canvas.set({
      backgroundColor: 'transparent'
    });
    this.innerCanvas = {
      length: this.canvas.getWidth() * .8,
      depth: this.canvas.getHeight() * .8
    };
    this.startPoint = {
      left: 0,
      top: 0
    };
    this.multFactor = this.innerCanvas.length / this.product.length;
  }

  private drawInnerCanvas() {
    this.innerCanvas['obj'] = new fabric.Rect({
      left: this.startPoint.left,
      top: this.startPoint.top,
      width: this.innerCanvas.length,
      height: this.innerCanvas.depth,
      fill: 'transparent',
      stroke: 'transparent'
    });
    this.canvas.add(this.innerCanvas['obj']);
  }
}
