import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { Response } from 'express';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styles: []
})
export class PageNotFoundComponent implements OnInit {
  response: Response;
  constructor(@Optional() @Inject(RESPONSE) response: any) {
    this.response = response;
  }

  ngOnInit() {
    if (this.response) {
      this.response.status(404);
    }
  }

}
