import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {fabric} from 'fabric';
import {FormGroup} from '@angular/forms';
import {Observable, Subject, Subscription} from 'rxjs';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {XcoScrollToService} from '@conception/ng-xava-connect';
import { TrendlineConfiguratorBlueprintTopViewService } from '../../services/trendline-configurator-blueprint-top-view.service';
import { TrendlineConfiguratorBlueprintSideViewService } from '../../services/trendline-configurator-blueprint-side-view.service';
import {
  SideViewSvgElements,
  TopViewSvgElements,
  TrendlineConfiguratorPriceInterface, TrendlineConfiguratorProduct
} from '../../trendline-configurator.model';

@Component({
  selector: 'tc-blueprint',
  templateUrl: './trendline-configurator-blueprint.component.html',
  providers: [
    TrendlineConfiguratorBlueprintTopViewService,
    TrendlineConfiguratorBlueprintSideViewService],
  styles: []
})
export class TrendlineConfiguratorBlueprintComponent implements OnInit, AfterViewInit, OnDestroy {
  canvas: any;
  grid = true;
  isDrawn: boolean;
  topViewElements = TopViewSvgElements;
  sideViewELements = SideViewSvgElements;
  svgElementsLoaded = false;
  overlayImage: string = null;
  overlayImageActive = false;
  activeView = 1;
  currentSideSlide = 0;
  disableBlueprintContainer: boolean;
  swiperConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    loop: true,
    init: false,
    pagination: false,
    navigation: false,
    slidesPerView: 1,
    spaceBetween: 20,
    observer: true,
    breakpoints: {},
    preloadImages: false,
    lazy: {
      loadingClass: 'lazy-loading',
      loadedClass: 'lazy-loaded',
      elementClass: 'lazy-background'
    }
  };

  private updateSubscription: Subscription;

  @Input() price: TrendlineConfiguratorPriceInterface;
  @Input() form: FormGroup;
  @Input() showResults: Subject<any>;
  @Input() emitViewsUpdate: Observable<void>;
  @Output() updatedViews = new EventEmitter<any>();
  @Output() overlayOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateFinish = new EventEmitter<boolean>();

  constructor(
    private product: TrendlineConfiguratorProduct,
    private scrollToService: XcoScrollToService,
    private topViewService: TrendlineConfiguratorBlueprintTopViewService,
    private sideViewService: TrendlineConfiguratorBlueprintSideViewService
  ) {
  }

  ngOnInit() {
    this.canvas = new fabric.StaticCanvas('canvas', {backgroundColor: '#F5F4F5'});
    this.updateSubscription = this.emitViewsUpdate.subscribe(() => this.updateImages());
  }

  ngAfterViewInit() {
    this.loadSvgElements();

    this.form.statusChanges.subscribe(() => {
      this.activeView = 1;
    });

    this.showResults.subscribe( () => {
      this.getImages();
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  public scrollToPdfSection($event) {
    $event.preventDefault();
    if ($event.target.getAttribute('href') === '#') {
      this.scrollToService.scrollTo(
        document.querySelector('#tc-formsection-05'),
        '.header--sticky',
        500);
    }
  }

  private loadSvgElements() {
    setTimeout(() => {
      this.createTopViewSvgElements();
      this.createSideViewSvgElements();
    }, 1000);
  }

  private createTopViewSvgElements() {
    const that = this;
    for (const elm in this.topViewElements) {
      if (this.topViewElements.hasOwnProperty(elm)) {
        fabric.loadSVGFromURL('assets/svg/trendline-configurator/top-view/' + this.topViewElements[elm].name + '.svg', function (objects, options) {
          that.topViewElements[elm]['svg'] = fabric.util.groupSVGElements(objects).toSVG();
        });
      }
    }
  }

  private createSideViewSvgElements() {
    const that = this,
          floorHeights = [2600, 2850, 3100, 4000],
          stairsSides = [];

    for (const side in this.sideViewELements.stairsElements) {
      if (this.sideViewELements.stairsElements.hasOwnProperty(side)) {
        stairsSides.push(side);
      }
    }
    const stringyfiedObj = JSON.stringify(this.sideViewELements.floorSpecific);
    floorHeights.forEach(floorHeight => {
      if (stringyfiedObj) {
        this.sideViewELements[floorHeight] = JSON.parse(stringyfiedObj);
      }
      // console.log(this.sideViewELements[floorHeight]);
      for (const elm in this.sideViewELements[floorHeight]) {
        if (this.sideViewELements[floorHeight].hasOwnProperty(elm)) {
          fabric.loadSVGFromURL('assets/svg/trendline-configurator/side-view/' + floorHeight + '/' + this.sideViewELements[floorHeight][elm].name + '.svg', function (objects, options) {
            that.sideViewELements[floorHeight][elm]['svg'] = fabric.util.groupSVGElements(objects).toSVG();
          });
        }
      }
    });

    delete this.sideViewELements.floorSpecific;

    stairsSides.forEach(side => {
      for (const elm in this.sideViewELements.stairsElements[side]) {
        if (this.sideViewELements.stairsElements[side].hasOwnProperty(elm)) {
          fabric.loadSVGFromURL('assets/svg/trendline-configurator/side-view/stairs-elements/' + side + '/' + this.sideViewELements.stairsElements[side][elm].name + '.svg', function (objects, options) {
            that.sideViewELements.stairsElements[side][elm]['svg'] = fabric.util.groupSVGElements(objects).toSVG();
          });
        }
      }
    });

    for (const elm in this.sideViewELements.general) {
      if (this.sideViewELements.general.hasOwnProperty(elm)) {
        fabric.loadSVGFromURL('assets/svg/trendline-configurator/side-view/general/' + this.sideViewELements.general[elm].name + '.svg', function (objects, options) {
          that.sideViewELements.general[elm]['svg'] = fabric.util.groupSVGElements(objects).toSVG();
        });
      }
    }
  }

  getOverlayImage() {
    if (this.activeView !== 0) {
      return this.product.blueprintImages.topView[this.activeView - 1]['img'];
    } else {
      return this.product.blueprintImages.sideView[this.currentSideSlide]['img'];
    }
  }

  getImages() {
    this.isDrawn = false;
    this.product.blueprintImages.topView = this.topViewService.generateImages(this.canvas, this.topViewElements);
    this.product.blueprintImages.sideView = this.sideViewService.generateImages(this.canvas, this.sideViewELements);
    this.overlayImage = this.product.blueprintImages.topView[0]['img'];
    this.isDrawn = true;
    this.disableBlueprintContainer = false;
    const optionArray = ['flanks', 'variant', 'length', 'depth', 'grid_unit_depth', 'grid_unit_length', 'room_number_ff', 'room_number_sf', 'stageHeight', 'firstFloor', 'secondFloor'];
    if (this.isDrawn && !this.disableBlueprintContainer) {
      optionArray.forEach(elm => {
        if (this.form.controls[elm] !== undefined) {
          this.form.controls[elm].valueChanges.subscribe((val) => {
            this.disableBlueprintContainer = true;
          });
        }
      });
    }
  }

  updateImages() {
    this.getImages();
    this.updatedViews.emit(this.product.blueprintImages);
    this.updateFinish.emit(true);
    this.updateSubscription.unsubscribe();
  }
}
