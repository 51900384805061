import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeDE from '@angular/common/locales/de';
import { AppComponent } from './app.component';
import { NgXavaConnectModule, XcoPageBuilderComponent } from '@conception/ng-xava-connect';
import { PullDownComponent } from './shared/pull-down/pull-down.component';
import { TrendlineConfiguratorSectionComponent } from './components/trendline-configurator-section/trendline-configurator-section.component';
import {
  TrendlineConfiguratorSectionButtonIconDirective,
  TrendlineConfiguratorSectionCheckboxIconDirective, TrendlineConfiguratorSectionRadioIconDirective,
  TrendlineConfiguratorSectionRangeDirective,
  TrendlineConfiguratorSectionSelectDirective
} from './components/trendline-configurator-section/trendline-configurator-section.directive';
import { TrendlineConfiguratorChosenDimensionsComponent } from './components/trendline-configurator-chosen-dimensions/trendline-configurator-chosen-dimensions.component';
import { TrendlineConfiguratorRoomDivisionComponent } from './components/trendline-configurator-room-division/trendline-configurator-room-division.component';
import { TrendlineConfiguratorRoomDivisionSelectDirective } from './components/trendline-configurator-room-division/trendline-configurator-room-division.directive';
import { TrendlineConfiguratorReportingPriceOfferComponent } from './components/trendline-configurator-reporting-price-offer/trendline-configurator-reporting-price-offer.component';
import { TrendlineConfiguratorBlueprintComponent } from './components/trendline-configurator-blueprint/trendline-configurator-blueprint.component';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { InlineSVGModule } from 'ng-inline-svg';
import { RangerInputComponent } from './shared/ranger-input/ranger-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng5SliderModule } from 'ng5-slider';
import { RouterModule } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { registerLocaleData } from '@angular/common';
import { DefaultTrendlineConfiguratorProduct, TrendlineConfiguratorProduct } from './trendline-configurator.model';
import { environment } from '../environments/environment';
import { PreloaderComponent } from './shared/preloader/preloader.component';
import { contentElements } from './xco/content-elements';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './static/header/header.component';
import { FooterComponent } from './static/footer/footer.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    PullDownComponent,
    HeaderComponent,
    TrendlineConfiguratorSectionComponent,
    TrendlineConfiguratorSectionButtonIconDirective,
    TrendlineConfiguratorSectionSelectDirective,
    TrendlineConfiguratorSectionCheckboxIconDirective,
    TrendlineConfiguratorSectionRadioIconDirective,
    TrendlineConfiguratorSectionRangeDirective,
    TrendlineConfiguratorChosenDimensionsComponent,
    TrendlineConfiguratorRoomDivisionComponent,
    TrendlineConfiguratorRoomDivisionSelectDirective,
    TrendlineConfiguratorReportingPriceOfferComponent,
    TrendlineConfiguratorBlueprintComponent,
    RangerInputComponent,
    PreloaderComponent,
    PageNotFoundComponent,
    FooterComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgXavaConnectModule.forRoot(environment, contentElements),
    SwiperModule,
    InlineSVGModule.forRoot({baseUrl: '/assets/svg/'}),
    NgSelectModule,
    Ng5SliderModule,
    RouterModule.forRoot([
      {path: '**', component: XcoPageBuilderComponent},
      {path: '', redirectTo: '/de/start', pathMatch: 'full'},
      {path: 'de', redirectTo: '/de/start', pathMatch: 'full'},
    ], {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top'
    }),
  ],
  providers: [
    NgxImageCompressService,
    Title,
    Meta,
    {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG},
    {provide: TrendlineConfiguratorProduct, useValue: DefaultTrendlineConfiguratorProduct},
    {provide: LOCALE_ID, useValue: 'de'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
