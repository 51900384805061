export interface TrendlineConfiguratorModel {
  headline: string;
  subline?: string;
  projectSubline?: boolean;
  formSections: TrendlineConfiguratorFormSectionModel[];
}

export interface TrendlineConfiguratorFormSectionModel {
  headline?: string;
  subline?: string;
  topspace?: boolean;
  pulldown?: boolean;
  submitButton?: TrendlineConfiguratorFormSubmitButtonInterface;
  formElements?: TrendlineConfiguratorFormElementInterface[];
  customArea?: any;
}

interface TrendlineConfiguratorFormSubmitButtonInterface {
  label: string;
  action: string;
}

export interface TrendlineConfiguratorFormElementInterface {
  type: string;
  grid?: string;
  name?: string;
  optional?: boolean;
  variant?: string;
  value?: any;
  relation?: string;
  label?: string;
  content?: any;
  placeholder?: string;
  validators?: any[];
}

export interface TrendlineConfiguratorSingleFloor {
  floorHeight?: number;
  roomAmount?: number;
  outerWall?: number;
  outerFillerWall?: number;
  outerDoor?: number;
  solidWall?: number;
  solidFillerWall?: number;
  door?: number;
  glassWall?: number;
  glassFillerWall?: number;
  innerDoor?: number;
  innerWall?: number;
  innerFillerWall?: number;
  innerRoomGridUnitsLength?: number[];

  wallConnectionProfile?: number;
  hollowProfile?: number;
  cornerProfile?: number;

  flooring?: number;
  cleaderAngle?: number;            // Verkleidungswinkel
  roofCapAngle?: number;            // Dachabschlusswinkel
  falseCeiling?: number;            // Zwischendecke
  ceilingSlab?: number;             // erweitertes Deckenauflager
  beam?: number;                    // Unterzug
  pillar?: number;                  // Stütze
  partitionPillar?: number;         // Trennnwandstütze

}

export class TrendlineConfiguratorProduct {
  gridUnit?: number;
  roofHeight?: number;
  occursWide?: number;
  stagePodestHeight?: number;
  falseCeilingHeight?: number;
  stageHeight?: number;
  existingWallHeight: number;
  passWallUnitMin?: number;
  cornerProfileUnit?: number;
  wallConnectionProfileUnit?: number;
  outerFillerLength?: number;
  outerFillerDepth?: number;
  innerFillersize?: number;
  floors?: TrendlineConfiguratorSingleFloor[];
  floorsLength?: number;
  stage?: boolean;
  stairway?: boolean;
  stairwayData?: any;
  roof?: boolean;
  length?: number;
  depth?: number;
  height?: number;
  maxRoom?: number;
  flanks?: number;
  options?: TrendlineConfiguratorProductOptionsInterface;
  zipCode?: number;
  blueprintImages?: TrendlineConfiguratorBlueprintImagesInterface;
}

interface TrendlineConfiguratorBlueprintImagesInterface {
  topView: any[];
  sideView: any[];
}

interface TrendlineConfiguratorProductOptionsInterface {
  floorVariant: 'floor_hall' | 'floor_wood' | 'floor_drypaint';
  epack: boolean;
  soundInsulation: boolean;
  wallfan: boolean;
  climacompact: boolean;
  convector: boolean;
}
export interface TrendlineConfiguratorPriceInterface {
  shipping: number;
  loadmeter: number;
  material: number;
  options: number;
  optionAmount: TrendlineConfiguratorPriceOptionAmountInterface;
  montage: number;
  withoutOptions: number;
  optionsOnly: number;
  overall: number;
}

interface TrendlineConfiguratorPriceOptionAmountInterface {
  wallfan: number;
  climacompact: number;
  convector: number;
}

export interface TrendlineConfiguratorSingleItemCostsInterface {
  price: number;
  workAmount: number;
}

export interface TrendlineConfiguratorSingleDrawingElementInterface {
  type: string;
  w: number;
  h: number;
  stroke?: string;
  fill?: string;
}

export const DefaultTrendlineConfiguratorProduct = {
  gridUnit: 1026,
  roofHeight: 100,
  falseCeilingHeight: 204,
  stagePodestHeight: 300,
  occursWide: 185,
  stageHeight: 0,
  existingWallHeight: 205,
  passWallUnitMin: 500,
  cornerProfileUnit: 62,
  wallConnectionProfileUnit: 100,
  outerFillerLength: 0,
  outerFillerDepth: 0,
  innerFillersize: 0,
  floors: [],
  floorsLength: 0,
  stage: false,
  stairway: false,
  roof: true,
  length: 0,
  depth: 0,
  maxRoom: 0,
  flanks: 0,
  zipCode: '',
  options: {
    floorVariant: 'floor_hall',
    epack: false,
    soundInsulation: false,
    wallfan: false,
    climacompact: false,
    convector: false
  },
  blueprintImages: {
    topView: [],
    sideView: []
  }
};

export const TopViewSvgElements = {
  existingWall: { name: 'existing-wall' },
  cornerProfile: { name: 'corner-profile' },
  solidWall: { name: 'solid-wall' },
  solidFillerWall: { name: 'solid-wall' },
  glassWall: { name: 'glass-wall' },
  glassFillerWall: { name: 'glass-wall' },
  wallConnectionProfile: { name: 'wall-connection-profile' },
  wallConnectionProfileReversed: { name: 'wall-connection-profile-reversed' },
  innerDoor: { name: 'inner-door' },
  outerDoor: {name: 'outer-door'},
  separateWallDoor: {name: 'separate-wall-door'},
  partitionPillar: { name: 'partition-pillar' },
  stairsTop2600: {name: 'stairs-top-2600'},
  stairsBottom2600: {name: 'stairs-bottom-2600'},
  stairsTop2850: {name: 'stairs-top-2850'},
  stairsBottom2850: {name: 'stairs-bottom-2850'},
  stairsTop3100: {name: 'stairs-top-3100'},
  stairsBottom3100: {name: 'stairs-bottom-3100'},
  stairsPodest: {name: 'stairs-podest'},
  stairsMiddle: {name: 'stairs-middle'},
  stairsFeet: {name: 'stairs-feet'}
};

export const SideViewSvgElements = {
  floorSpecific: {
    existingWall: {name: 'existing-wall'},
    existingWallWithoutRoof: {name: 'existing-wall-without-roof'},
    wallConnectionProfile: { name: 'wall-connection-profile' },
    cornerProfileLeft: {name: 'corner-profile-left'},
    cornerProfileRight: {name: 'corner-profile-right'},
    glassWall: { name: 'glass-wall' },
    glassMirroring: { name: 'glass-wall-mirroring' },
    glassFillerWallLeft: { name: 'glass-filler-wall-left' },
    glassFillerWallCenter: { name: 'glass-filler-wall-center' },
    glassFillerWallRight: { name: 'glass-filler-wall-right' },
    solidWall: { name: 'solid-wall' },
    solidFillerWallLeft: { name: 'solid-filler-wall-left' },
    solidFillerWallCenter: { name: 'solid-filler-wall-center' },
    solidFillerWallRight: { name: 'solid-filler-wall-right' },
    outerDoor: {name: 'outer-door'}
  },
  stairsElements: {
    side: {
      2600: {name: '2600'},
      2850: {name: '2850'},
      3100: {name: '3100'},
      podest: {name: 'podest'},
      rail: {name: 'rail'},
      supportBar: {name: 'support-bar'}
    },
    front: {
      2600: {name: '2600'},
      2850: {name: '2850'},
      3100: {name: '3100'},
      feet: {name: 'feet'},
      middle: {name: 'middle'},
      podest: {name: 'podest'},
      stair: {name: 'stair'}
    },
    back: {
      2600: {name: '2600'},
      2850: {name: '2850'},
      3100: {name: '3100'},
      feet: {name: 'feet'},
      middle: {name: 'middle'},
      podest: {name: 'podest'},
      stair: {name: 'stair'}
    }
  },
  general: {
    wallBar: {name: 'wall-bar'},
    supportBarTop: {name: 'support-bar-top'},
    supportBarMiddle: {name: 'support-bar-middle'},
    supportBarBottom: {name: 'support-bar-bottom'},
    stage: {name: 'stage'}
  }
};
