import {Injectable} from '@angular/core';
import {TrendlineConfiguratorProduct} from '../trendline-configurator.model';
import {fabric} from 'fabric';
import {TrendlineConfiguratorBlueprintCalculationService} from './trendline-configurator-blueprint-calculation.service';

@Injectable()
export class TrendlineConfiguratorBlueprintSideViewService {
  canvas: any;
  elements: any;
  drawingObject: any;
  startPoint: any;
  mainBox: fabric.Object;
  pageLegend = [];
  multFactor: number;
  fontSize: number;
  sideSize: number;
  sideFillerSize: number;
  canvasObjectsWrapper: any;
  imgLinks: Array<any>;
  constructor(
    private product: TrendlineConfiguratorProduct,
    private blueprintCalculation: TrendlineConfiguratorBlueprintCalculationService
  ) {}

  generateImages(canvas, svgElements) {
    this.imgLinks = [];
    this.canvas = canvas;
    this.elements = svgElements;
    this.drawingObject = this.blueprintCalculation.getSideDrawingObject();
    switch (this.product.flanks) {
      case 4:
        this.imgLinks.push({
          'name': 'viewA',
          'img': this.draw(this.drawingObject['west'], 'west', 'viewA')
        });
        this.imgLinks.push({
          'name': 'viewB',
          'img': this.draw(this.drawingObject['south'], 'south', 'viewB')
        });
        this.imgLinks.push({
          'name': 'viewC',
          'img': this.draw(this.drawingObject['east'], 'east', 'viewC')
        });
        this.imgLinks.push({
          'name': 'viewD',
          'img': this.draw(this.drawingObject['north'], 'north', 'viewD')
        });
        break;
      case 3:
        this.imgLinks.push({
          'name': 'viewA',
          'img': this.draw(this.drawingObject['west'], 'west', 'viewA')
        });
        this.imgLinks.push({
          'name': 'viewB',
          'img': this.draw(this.drawingObject['south'], 'south', 'viewB')
        });
        this.imgLinks.push({
          'name': 'viewC',
          'img': this.draw(this.drawingObject['north'], 'north', 'viewC')
        });
        break;
      case 2:
        this.imgLinks.push({
          'name': 'viewA',
          'img': this.draw(this.drawingObject['west'], 'west', 'viewA')
        });
        this.imgLinks.push({
          'name': 'viewB',
          'img': this.draw(this.drawingObject['north'], 'north', 'viewB')
        });
        break;
      case 1:
        this.imgLinks.push({
          'name': 'viewA',
          'img': this.draw(this.drawingObject['west'], 'west', 'viewA')
        });
        break;
    }
    return this.imgLinks;
  }

  private draw(drawingArray, side: string, view: string) {
    this.canvas.clear();
    this.canvasObjectsWrapper = new fabric.Group();
    this.setCanvas();
    this.setSideSize(side);
    this.startPoint = {left: 0, top: 0};
    this.canvas.add(this.canvasObjectsWrapper);
    this.product.floors.forEach((floor, floorIndex) => {
      let prevIndex = 0;
      drawingArray.forEach( (elm, index) => {
        if (prevIndex < floorIndex) {
          this.setCoordinatesForSecondFloor();
        }

        if (elm.name.match('Filler')) {
          if (elm.width === 0) { return; }
          this.createFillerDataElement(elm.name, floor.floorHeight);
          return;
        }
        switch (elm.name) {
          case 'existingWall':
            this.createExistingWallFoundation(elm, floor.floorHeight);
            break;
          case 'cornerProfile':
            elm.name = (index === 0) ? 'cornerProfileLeft' : 'cornerProfileRight';
            break;
        }
        const svg = this.elements[floor.floorHeight][elm.name].svg;
        this.drawSingleSvg(elm, svg, {});
        prevIndex = floorIndex;
      });

      if (this.product.roof && floorIndex === (this.product.floorsLength - 1)) {
        this.setRoof(this.product.roofHeight, true);
      }

      if (floorIndex < (this.product.floorsLength - 1)) {
        this.setRoof(this.product.falseCeilingHeight);
      }
    });

    if (this.product.stage) {
      this.setStagePodest();
    }
    this.canvasObjectsWrapper.clone( obj => this.mainBox = obj );
    this.existingWall(side);
    this.stairs(side);

    this.fixPositioning();
    this.setLegends(view);
    this.fixCanvasDimensions();

    return this.canvas.toDataURL({
        format: 'img',
        quality: 1,
        enableRetinaScaling: true
      });
  }

  private existingWall(side) {
    const wallBarSvg = this.elements.general.wallBar.svg;
    const cwrapperCoordsTopLeft = this.mainBox.aCoords.tl;
    const cwrapperCoordsTopRight = this.mainBox.aCoords.tr;
    const cwrapperCoordsBottomLeft = this.mainBox.aCoords.bl;
    const cwrapperCoordsBottomRight = this.mainBox.aCoords.br;
    const offsetH = this.getSvgObjProps(wallBarSvg).scaledWidth;
    const offsetV = (this.mainBox.getScaledHeight() + offsetH);
    const offsetWidth = this.mainBox.getScaledWidth() + (offsetH * 2);
    const barOptionsLeft = {
      left: cwrapperCoordsTopLeft.x - offsetH,
      top: cwrapperCoordsTopLeft.y - offsetH,
      height: offsetV / this.multFactor
    };
    const barOptionsRight = {
      left: cwrapperCoordsTopRight.x,
      top: cwrapperCoordsTopLeft.y - offsetH,
      height: offsetV / this.multFactor
    };
    const lineLeftVert = this.createLine(new fabric.Point(cwrapperCoordsTopLeft.x - offsetH, cwrapperCoordsTopLeft.y - offsetH), offsetV, {angle: 90});
    const lineRightVert = this.createLine(new fabric.Point(cwrapperCoordsTopRight.x + offsetH, cwrapperCoordsTopRight.y - offsetH), offsetV , {angle: 90});
    const lineLeftBottom = this.createLine(new fabric.Point(cwrapperCoordsBottomLeft.x - offsetH, cwrapperCoordsBottomLeft.y), offsetH);
    const lineMiddleBottom = this.createLine(new fabric.Point(cwrapperCoordsBottomLeft.x, cwrapperCoordsBottomLeft.y), this.mainBox.getScaledWidth());
    const lineRightBottom = this.createLine(new fabric.Point(cwrapperCoordsBottomRight.x, cwrapperCoordsBottomRight.y), offsetH);
    const lineTop = this.createLine(new fabric.Point(cwrapperCoordsTopLeft.x - offsetH, cwrapperCoordsTopLeft.y - offsetH), offsetWidth);
    const dashedLeft = this.createLine(new fabric.Point(cwrapperCoordsTopLeft.x, cwrapperCoordsTopLeft.y), this.mainBox.getScaledHeight(), {angle: 90, strokeDashArray: [40, 40]});
    const dashedRight = this.createLine(new fabric.Point(cwrapperCoordsTopRight.x, cwrapperCoordsTopRight.y), this.mainBox.getScaledHeight(), {angle: 90, strokeDashArray: [40, 40]});
    const dashedTop = this.createLine(new fabric.Point(cwrapperCoordsTopLeft.x, cwrapperCoordsTopLeft.y), this.mainBox.getScaledWidth(), {strokeDashArray: [40, 40]});

    if (this.product.flanks < 4) {
      this.canvasObjectsWrapper.addWithUpdate(lineLeftVert);
      this.canvasObjectsWrapper.addWithUpdate(lineRightVert);
      this.canvasObjectsWrapper.addWithUpdate(lineTop);
      this.canvasObjectsWrapper.addWithUpdate(dashedLeft);
      this.canvasObjectsWrapper.addWithUpdate(dashedRight);
      this.canvasObjectsWrapper.addWithUpdate(lineLeftBottom);
      this.canvasObjectsWrapper.addWithUpdate(lineRightBottom);
    }

    if (!this.product.roof && this.product.flanks < 4) {
      this.canvasObjectsWrapper.addWithUpdate(dashedTop);
    }

    if (this.product.stage && this.product.flanks < 4) {
      this.canvasObjectsWrapper.addWithUpdate(lineMiddleBottom);
    }

    switch (side) {
      case 'east':
        if (this.product.flanks <= 2) {
          this.canvasObjectsWrapper.remove(lineLeftVert);
          this.canvasObjectsWrapper.remove(dashedLeft);
          this.drawSingleSvg({name: 'wallBar'}, wallBarSvg, barOptionsLeft);
        }

        if (this.product.flanks === 1) {
          this.canvasObjectsWrapper.remove(lineRightVert);
          this.canvasObjectsWrapper.remove(dashedRight);
          this.drawSingleSvg({name: 'wallBar'}, wallBarSvg, barOptionsRight);
        }
        break;

      case 'south':
        this.canvasObjectsWrapper.remove(dashedLeft);
        this.canvasObjectsWrapper.remove(dashedTop);
        if (this.product.flanks <= 3) {
          this.canvasObjectsWrapper.remove(lineRightVert);
          this.canvasObjectsWrapper.remove(dashedRight);
          this.drawSingleSvg({name: 'wallBar'}, wallBarSvg, barOptionsRight);
        }

        if (this.product.flanks < 3) {
          if (!this.product.roof) {
            this.canvasObjectsWrapper.add(dashedTop);
          }
          this.canvasObjectsWrapper.add(dashedLeft);
        }
        break;

      case 'west':
        this.canvasObjectsWrapper.remove(dashedRight);
        this.canvasObjectsWrapper.remove(dashedLeft);
        this.canvasObjectsWrapper.remove(dashedTop);
        if (this.product.flanks <= 2) {
          this.canvasObjectsWrapper.remove(lineRightVert);
          this.drawSingleSvg({name: 'wallBar'}, wallBarSvg, barOptionsRight);
        }

        if (this.product.flanks === 1) {
          this.canvasObjectsWrapper.remove(lineLeftVert);
          this.drawSingleSvg({name: 'wallBar'}, wallBarSvg, barOptionsLeft);
        }
        break;

      case 'north':
        this.canvasObjectsWrapper.remove(dashedRight);
        this.canvasObjectsWrapper.remove(dashedTop);
        if (this.product.flanks <= 3) {
          this.canvasObjectsWrapper.remove(lineLeftVert);
          this.canvasObjectsWrapper.remove(dashedLeft);
          this.drawSingleSvg({name: 'wallBar'}, wallBarSvg, barOptionsLeft);
        }

        if (this.product.flanks < 2) {
          this.canvasObjectsWrapper.add(dashedRight);
          if (!this.product.roof) {
            this.canvasObjectsWrapper.add(dashedTop);
          }
        }
        break;
    }
  }

  private createExistingWallFoundation(elm, floorHeight: number) {
    const elmName = this.product.roof ? 'existingWall' : 'existingWallWithoutRoof';
    const svg = this.elements[floorHeight][elmName].svg;
    this.drawSingleSvg(elm, svg, {scaleX: this.sideSize * this.multFactor});
  }

  private setCoordinatesForSecondFloor() {
    this.startPoint = {
      left: this.canvasObjectsWrapper.aCoords.tl.x,
      top: this.canvasObjectsWrapper.aCoords.tl.y - (this.product.floors[this.product.floorsLength - 1].floorHeight * this.multFactor),
    };
  }

  private stairs(direction: string) {
    const stairsObj = this.product.flanks !== 1 ? {east: 'back', west: 'front', north: 'side' } : { west: 'side', north: 'back', south: 'front'};
    if (!stairsObj.hasOwnProperty(direction)) { return; }
    if (this.product.stairway && !this.product.stage) {
      this.setStairs(stairsObj[direction]);
    } else if (this.product.stairway && this.product.stage) {
      this.setStageStairs(stairsObj[direction]);
    }
  }

  private setStairs(direction) {
    const svg = this.elements.stairsElements[direction][this.product.floors[0].floorHeight].svg;
    const options: {top: number, left: number} = {
      top: this.mainBox.aCoords.bl.y - this.getSvgObjProps(svg).scaledHeight,
      left: 0
    };
    switch (direction) {
      case 'front':
        options.left = this.mainBox.aCoords.tl.x - this.getSvgObjProps(svg).scaledWidth;
        break;
      case 'side':
        options.left = this.mainBox.aCoords.tl.x;
        break;
      case 'back':
        options.left = this.mainBox.aCoords.tr.x;
        break;
    }
    this.drawSingleSvg({name: 'stairs', direction: direction}, svg, options);
  }

  private setStageStairs(direction) {
    if (direction === 'side') {
      this.setStageStairsSideView(direction);
    } else {
      this.setStageStairsFrontBack(direction);
    }
  }

  private setStagePodest() {
    const stageHeight = this.product.stageHeight * this.multFactor;
    const rectHor = new fabric.Rect({
      width: this.canvasObjectsWrapper.getScaledWidth(),
      height: this.product.stagePodestHeight * this.multFactor,
      top: this.canvasObjectsWrapper.aCoords.bl.y,
      left: this.canvasObjectsWrapper.aCoords.bl.x,
      fill: '#000',
      stroke: '#000',
      strokeWidth: 0
    });
    const rectLeft = new fabric.Rect({
      width: 150 * this.multFactor,
      height: stageHeight,
      top: this.canvasObjectsWrapper.aCoords.bl.y + (this.product.stagePodestHeight * this.multFactor),
      left: this.canvasObjectsWrapper.aCoords.bl.x,
      fill: '#000',
      stroke: '#000',
      strokeWidth: 0
    });
    const rectRight = new fabric.Rect({
      width: 150 * this.multFactor,
      height: stageHeight,
      top: this.canvasObjectsWrapper.aCoords.bl.y + (this.product.stagePodestHeight * this.multFactor),
      left: this.canvasObjectsWrapper.aCoords.br.x - (150 * this.multFactor),
      fill: '#000',
      stroke: '#000',
      strokeWidth: 0
    });
    this.canvasObjectsWrapper.addWithUpdate(rectHor);
    this.canvasObjectsWrapper.addWithUpdate(rectLeft);
    this.canvasObjectsWrapper.addWithUpdate(rectRight);
  }

  private setStageStairsFrontBack(direction) {
    const stairs = new fabric.Group();
    const srcObj = this.elements.stairsElements[direction];
    const podest = this.getSvgObjProps(srcObj.podest.svg).obj.scale(this.multFactor);
    const feet = this.getSvgObjProps(srcObj.feet.svg).obj.scale(this.multFactor);
    stairs.addWithUpdate(podest.set({top: stairs.aCoords.bl.y, left: stairs.aCoords.bl.x}));
    for (let i = 1; i <= this.product.stairwayData.amount; i++) {
      stairs.addWithUpdate(this.getSvgObjProps(srcObj.stair.svg).obj.scale(this.multFactor).set({top: stairs.aCoords.bl.y, left: stairs.aCoords.bl.x}));
      if (i === this.product.stairwayData.amount) {
        stairs.addWithUpdate(feet.set({top: stairs.aCoords.bl.y, left: stairs.aCoords.bl.x}));
      } else {
        stairs.addWithUpdate(this.getSvgObjProps(srcObj.middle.svg).obj.scale(this.multFactor).set({top: stairs.aCoords.bl.y, left: stairs.aCoords.bl.x}));
      }
    }
    const stairsScaleFactor = ((this.product.stageHeight + this.product.stagePodestHeight - 55) * this.multFactor) / (stairs.getScaledHeight() - podest.getScaledHeight());
    stairs.scale(stairsScaleFactor);
    const options: {top: number, left: number} = {
      top: this.mainBox.aCoords.bl.y - stairs.getScaledHeight(),
      left: direction === 'front' ? this.mainBox.aCoords.bl.x - stairs.getScaledWidth() : this.mainBox.aCoords.br.x
    };
    this.canvasObjectsWrapper.addWithUpdate(stairs.set(options));
  }

  private setStageStairsSideView(direction) {
    const stairs = new fabric.Group();
    const srcObj = this.elements.stairsElements[direction];
    const podest = this.getSvgObjProps(srcObj.podest.svg).obj.scale(this.multFactor);
    const rail = this.getSvgObjProps(srcObj.rail.svg).obj.scale(this.multFactor);
    stairs.addWithUpdate(podest.set({top: stairs.aCoords.bl.y, left: stairs.aCoords.bl.x}));

    const stageHeight = ((this.product.stageHeight + this.product.stagePodestHeight) * this.multFactor) + podest.getScaledHeight();
    const stageWidth = (this.product.occursWide * this.product.stairwayData.amount);
    const stageLength = Math.sqrt(Math.pow(this.product.stageHeight, 2) + Math.pow(stageWidth, 2));
    const options = {
      top: this.mainBox.aCoords.bl.y - stairs.getScaledHeight() - ((this.product.stageHeight + this.product.stagePodestHeight) * this.multFactor),
      left: this.mainBox.aCoords.bl.x
    };
    this.canvasObjectsWrapper.addWithUpdate(stairs.set(options));
    rail.scaleToWidth(stageLength * this.multFactor);
    rail.scaleY = this.multFactor;

    this.canvasObjectsWrapper.addWithUpdate(rail.set({top: stairs.aCoords.tl.y, left: this.mainBox.aCoords.bl.x + podest.getScaledWidth()}));
    do {
      rail.skewY += .05;
    } while ( rail.getScaledHeight() <= stageHeight + 5);

    // rendering supportbars from stairs
    const supportBarArray: Array<{name: string, svg?: fabric.Object, width?: number, height?: number}> = [{name: 'supportBarTop'}, {name: 'supportBarMiddle'}, {name: 'supportBarBottom'}];
    supportBarArray.forEach(elm => {
      const obj = this.getSvgObjProps(this.elements.general[elm.name].svg);
      elm.svg = obj.obj.scale(this.multFactor);
      elm.width = obj.width;
      elm.height = obj.height;
    });
    const supportBarHeight = ((this.product.stageHeight + this.product.stagePodestHeight) - supportBarArray[0].height - supportBarArray[supportBarArray.length - 1].height) * this.multFactor;
    supportBarArray[1].svg.set({scaleY: supportBarHeight});
    const supportBarGroupLeft = new fabric.Group();
    supportBarGroupLeft.addWithUpdate(supportBarArray[0].svg.set({left: supportBarGroupLeft.aCoords.bl.x, top: supportBarGroupLeft.aCoords.bl.y}));
    supportBarGroupLeft.addWithUpdate(supportBarArray[1].svg.set({left: supportBarGroupLeft.aCoords.bl.x, top: supportBarGroupLeft.aCoords.bl.y}));
    supportBarGroupLeft.addWithUpdate(supportBarArray[2].svg.set({left: supportBarGroupLeft.aCoords.bl.x, top: supportBarGroupLeft.aCoords.bl.y}));
    this.canvasObjectsWrapper.addWithUpdate(supportBarGroupLeft.set({left: podest.aCoords.bl.x, top: this.mainBox.aCoords.bl.y - supportBarGroupLeft.getScaledHeight()}));
    supportBarGroupLeft.clone(elm => {
      this.canvasObjectsWrapper.addWithUpdate(elm.set({top: this.mainBox.aCoords.bl.y - elm.getScaledHeight(), left: podest.aCoords.br.x - elm.getScaledWidth()}));
    });
  }

  private createLine(start: fabric.Point, size, lineOptions?) {
    const defaultOpts = {strokeWidth: 4, stroke: '#000'};
    const opts: Partial<any> = lineOptions ? {...defaultOpts, ...lineOptions} : defaultOpts;
    const coords = [
      start.x,
      start.y,
      start.x + (size / this.multFactor),
      start.y
    ];

    const line = new fabric.Line(coords, opts);
    line.scale(this.multFactor);
    line.set({
      top: start.y,
      left: start.x
    });
    return line;
  }

  private setRoof(height: number, top?: boolean) {
    const topOffset = height * this.multFactor;
    const left = this.createLine(new fabric.Point(this.canvasObjectsWrapper.aCoords.tl.x, this.canvasObjectsWrapper.aCoords.tl.y), topOffset, {angle: 270});
    const right = this.createLine(new fabric.Point(this.canvasObjectsWrapper.aCoords.tr.x, this.canvasObjectsWrapper.aCoords.tr.y), topOffset, {angle: 270});
    this.canvasObjectsWrapper.addWithUpdate(left);
    this.canvasObjectsWrapper.addWithUpdate(right);
    right.left = right.left - right.getScaledHeight();
    if (top) {
      const topLine = this.createLine(new fabric.Point(this.canvasObjectsWrapper.aCoords.tl.x, this.canvasObjectsWrapper.aCoords.tl.y), this.canvasObjectsWrapper.getScaledWidth());
      this.canvasObjectsWrapper.addWithUpdate(topLine);
    }
  }

  private getSvgObjProps(elem) {
    const objArray = [];
    fabric.loadSVGFromString(elem, (results) => {
      objArray.push(fabric.util.groupSVGElements(results));
    });
    return {
      width: objArray[0].getScaledWidth(),
      scaledWidth: objArray[0].getScaledWidth() * this.multFactor,
      height: objArray[0].getScaledHeight(),
      scaledHeight: objArray[0].getScaledHeight() * this.multFactor,
      obj: objArray[0]
    };
  }

  private drawSingleSvg(elm, svg, options) {
    const amount = elm.hasOwnProperty('amount') ? elm.amount : 1;
    const that = this;
    for (let i = 0; i < amount; i++) {
      fabric.loadSVGFromString(svg, (results) => {
        const obj = fabric.util.groupSVGElements(results);
        obj.set({left: that.startPoint.left, top: that.startPoint.top});
        if (options.hasOwnProperty('width')) {
          obj.scaleToWidth(options.width);
        }
        obj.scale(this.multFactor);
        obj.set(options);

        if (elm.name !== 'glassMirroring') {
          that.canvasObjectsWrapper.addWithUpdate(obj);
          that.startPoint = {left: obj.aCoords.tr.x, top: obj.aCoords.tr.y};
        }
      });
    }
  }

  private setSideSize(side: string) {
    const sideName = (side === 'east' || side === 'west') ? 'Length' : 'Depth';
    this.sideFillerSize = this.product['outerFiller' + sideName];
    this.sideSize = this.product[sideName.toLowerCase()];
  }

  private createFillerDataElement(name, floorHeight: number) {
    const array: {name: string, amount?: number, width?: number}[] = [
      {name: name + 'Left'},
      {name: name + 'Center'},
      {name: name + 'Right'}
    ];
    array.forEach(elm => elm['width'] = this.getSvgObjProps(this.elements[floorHeight][elm.name].svg).width );

    const sideFillerLength = array[0]['width'] + array[2]['width'];

    array[1]['amount'] = Math.round((this.sideFillerSize - sideFillerLength) / array[1]['width']);

    if (name.match('glass') ) {
      array.push({ name: 'glassMirroring' });
    }
    const fillerGroup = new fabric.Group();
    fillerGroup.set({left: this.startPoint.left, top: this.startPoint.top});
    array.forEach(elm => {
      const amount = elm.hasOwnProperty('amount') ? elm.amount : 1;
      const that = this;
      const svg = this.elements[floorHeight][elm.name].svg;
      for (let i = 0; i < amount; i++) {
        fabric.loadSVGFromString(svg, (results) => {
          const obj = fabric.util.groupSVGElements(results);
          obj.scale(this.multFactor);
          if (elm.name === 'glassMirroring') {
            obj.set({
              top: this.startPoint.top,
              left: fillerGroup.left + (fillerGroup.width / 2) - (obj.getScaledWidth() / 2)
            });
          } else {
            obj.set({left: that.startPoint.left, top: that.startPoint.top});
          }
          fillerGroup.addWithUpdate(obj);
          this.startPoint = {left: obj.aCoords.tr.x, top: obj.aCoords.tr.y};
        });
      }
    });
    this.canvasObjectsWrapper.addWithUpdate(fillerGroup);
    this.startPoint = {left: fillerGroup.aCoords.tr.x, top: fillerGroup.aCoords.tr.y};
  }

  private setCanvas() {
    let height = 0;
    let widthValue: number;
    let canvasWidth = (this.product.length >= this.product.depth) ? this.product.length : this.product.depth;
    this.product.floors.forEach(floor => height += floor.floorHeight);
    height += this.product.roof ? this.product.roofHeight : 0;
    height += this.product.floorsLength === 2 ? this.product.falseCeilingHeight : 0;
    height += this.product.stage ? this.product.stageHeight : 0;
    if (canvasWidth < 6000) {
      widthValue = 1500;
      canvasWidth = 6000;
    } else {
      widthValue = 2000;
    }
    const canvasHeight = (widthValue * height) / canvasWidth;
    this.canvas.setWidth(widthValue);
    this.canvas.setHeight(canvasHeight);
    this.canvas.set({
      backgroundColor: 'transparent'
    });

    this.multFactor = (this.canvas.getWidth() * .9) / canvasWidth;

    this.canvas.setWidth(this.canvas.getWidth() + ((1044 * 2) * this.multFactor));
  }

  private fixPositioning() {
    this.canvasObjectsWrapper.originX = 'center';
    this.canvasObjectsWrapper.originY = 'center';
    this.canvasObjectsWrapper.centeredScaling = true;
    this.canvas.viewportCenterObject(this.canvasObjectsWrapper);
  }

  private fixCanvasDimensions() {
    this.canvasObjectsWrapper = new fabric.Group();
    this.canvas.forEachObject(elm => this.canvasObjectsWrapper.addWithUpdate(elm) );
    this.canvas.set({
      width: this.canvasObjectsWrapper.getScaledWidth() + 150,
      height: this.canvasObjectsWrapper.getScaledHeight() + 150,
    });

    this.canvasObjectsWrapper.originX = 'center';
    this.canvasObjectsWrapper.originY = 'center';
    this.canvasObjectsWrapper.centeredScaling = true;
    this.canvas.viewportCenterObject(this.canvasObjectsWrapper);
  }


  private setLegends(side: string) {
    this.fontSize = 40 * (this.multFactor + .5);
    const offsetLeft = this.product.gridUnit * .5 * this.multFactor;
    const legendStartPointOffset = this.product.gridUnit * .1 * this.multFactor;
    const heightLegend = new fabric.Text(this.product.stage ? 'ca. ' + this.product.height.toLocaleString() : this.product.height.toLocaleString(), {
      angle: 270,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    let view = 'Ansicht A';
    switch (side) {
      case 'viewB':
        view = 'Ansicht B';
        break;
      case 'viewC':
        view = 'Ansicht C';
        break;
      case 'viewD':
        view = 'Ansicht D';
        break;
      case 'viewA':
        view = 'Ansicht A';
        break;
    }
    const viewLegend = new fabric.Text(view, {
      angle: 0,
      fontSize: this.fontSize,
      fontFamily: 'Arial',
      originX: 'center'
    });
    const arrowGroupLeft = new fabric.Group([
      new fabric.Rect({
        height: 1,
        width: legendStartPointOffset - offsetLeft,
        angle: 180,
        fill: '#0FFF00',
        left: this.canvasObjectsWrapper.aCoords.tr.x + legendStartPointOffset,
        top: this.canvasObjectsWrapper.aCoords.tl.y + 1,
      }),
      new fabric.Triangle({
        width: 10,
        height: 15,
        fill: '#0FFF00',
        left: this.canvasObjectsWrapper.aCoords.tr.x + offsetLeft,
        top: this.canvasObjectsWrapper.aCoords.tl.y,
        angle: 0,
        originX: 'center'
      }),
      new fabric.Rect({
        width: 1,
        height: this.canvasObjectsWrapper.getScaledHeight(),
        left: this.canvasObjectsWrapper.aCoords.tr.x + offsetLeft,
        top: this.canvasObjectsWrapper.aCoords.tl.y,
        fill: '#0FFF00',
        originX: 'center',
        angle: 0
      }),
      new fabric.Triangle({
        width: 10,
        height: 15,
        fill: '#0FFF00',
        left: this.canvasObjectsWrapper.aCoords.br.x + offsetLeft,
        top: this.canvasObjectsWrapper.aCoords.bl.y + 1,
        angle: 180,
        originX: 'center'
      }),
      new fabric.Rect({
        height: 1,
        width: legendStartPointOffset - offsetLeft,
        angle: 180,
        fill: '#0FFF00',
        left: this.canvasObjectsWrapper.aCoords.br.x + legendStartPointOffset,
        top: this.canvasObjectsWrapper.aCoords.bl.y
      })
    ]);

    this.canvas.add(arrowGroupLeft);
    this.canvas.add(heightLegend.set({
      left: this.canvasObjectsWrapper.aCoords.tr.x + offsetLeft + heightLegend.getScaledHeight(),
      top: this.canvasObjectsWrapper.aCoords.tl.y + (this.canvasObjectsWrapper.getScaledHeight() / 2)
    }));

    this.canvas.add(viewLegend.set({
      left: this.canvasObjectsWrapper.aCoords.tl.x - viewLegend.getScaledWidth() / 2 - offsetLeft,
      top: this.canvasObjectsWrapper.aCoords.tl.y - viewLegend.getScaledHeight() / 8
    }));
  }
}
