import {TrendlineConfiguratorModel, TrendlineConfiguratorFormSectionModel} from './trendline-configurator.model';

export const trendlineConfiguratorData: TrendlineConfiguratorModel[] = [
  {
    headline: 'Ausführungsvarianten',
    formSections: [
      {
        subline: 'Aufstellung wählen',
        formElements: [
          {
            type: 'radio-image',
            grid: 'col-12 flex-helper',
            value: '1g',
            name: 'variant',
            validators: [],
            content: [
              {
                value: '1g',
                label: '1-geschossig',
                img: 'assets/images/tc/variant/1_level.jpg'
              },
              {
                value: '2g',
                label: '2-geschossig',
                img: 'assets/images/tc/variant/2_level.jpg'
              },
              {
                value: 'stage',
                label: 'Auf Bühne',
                img: 'assets/images/tc/variant/on_stage.jpg'
              },
              {
                value: 'wallseparation',
                label: 'Trennwand',
                img: 'assets/images/tc/variant/div_wall.jpg'
              }
            ]
          }
        ]
      },
      {
        subline: 'Anbauvariante wählen',
        pulldown: true,
        formElements: [
          {
            type: 'radio-image',
            grid: 'col-12 flex-helper',
            value: 4,
            name: 'flanks',
            validators: [],
            content: [
              {
                value: 4,
                label: '4-seitig',
                img: 'assets/images/tc/sides/04.jpg'
              },
              {
                value: 3,
                label: '3-seitig',
                img: 'assets/images/tc/sides/03.jpg'
              },
              {
                value: 2,
                label: '2-seitig',
                img: 'assets/images/tc/sides/02.jpg'
              },
              {
                value: 1,
                label: '1-seitig',
                img: 'assets/images/tc/sides/01.jpg'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    headline: 'Abmessungen',
    subline: 'Wählen Sie Ihre gewünschten Abmessungen. Die Angaben können in standardisierten Wandrastern (1.026 mm breit) oder aber in einem freien Fixmaß angegeben werden.',
    formSections: [
      {
        customArea: 'chosen-dimensions'
      }
    ],
  },
  {
    headline: 'Raumaufteilung',
    formSections: [
      {
        customArea: 'room-division'
      },
      {
        headline: 'Richtpreis berechnen',
        subline: 'Bitte geben Sie noch den gewünschten Projektnamen und den Aufstellungsort an. ' +
          'Klicken sie anschließend auf Richtpreis errechnen.',
        pulldown: true,
        formElements: [
          {
            type: 'input',
            grid: 'col-12 col-ipad-portrait-4 col-desktop-smallest-3',
            placeholder: '',
            variant: 'text',
            name: 'project_name',
            validators: ['required'],
            label: 'Projekt-Name'
          }
        ]
      },
      {
        submitButton: {
          label: 'jetzt berechnen',
          action: 'calc'
        },
        formElements: [
          {
            content: '<h6>Bitte beachten – Allgemeine Information zum Richtpreisangebot</h6><p>Alle unsere Bauten sind grundsätzlich veränderbar und erweiterbar. Auch ein späteres Versetzen an einen anderen Standort ist problemlos möglich. Daher basiert die hier konfigurierte Ausführungsvariante lediglich auf normalen und üblichen Rahmenbedingungen. Besondere Einflüsse am jeweiligen Aufstellort und spezielle Auflagen (z.B. Berücksichtigung von Erdbebenzonen) müssen möglicherweise zusätzlich bewertet werden. Nähere Informationen erhalten Sie auf Wunsch mit einem nachfolgenden ausführlichen KLEUSBERG Angebot.</p>',
            grid: 'col-12 col-ipad-portrait-9 col-desktop-smallest-6',
            name: 'widerruf',
            type: 'html'
          }
        ]
      }
    ]
  },
  {
    headline: 'Richtpreis',
    formSections: [
      {
        customArea: 'blueprint'
      }
    ]
  },
  {
    headline: 'Optionen / Sonderausstattung',
    projectSubline: true,

    formSections: [
      {
        headline: 'Boden',
        topspace: true,
        formElements: [
          {
            type: 'radio-image',
            grid: 'wrap full-height horizontal-center',
            name: 'floorVariant',
            value: 'floor_hall',
            optional: true,
            validators: [],
            content: [
              {
                value: 'floor_hall',
                label: 'Auf Hallenboden',
                img: 'assets/images/tc/floor/hallfloor.jpg'
              },
              {
                value: 'floor_wood',
                label: 'Boden ungedämmt',
                img: 'assets/images/tc/floor/wooden.jpg',
                info: '<h3>Boden ungedämmt</h3>' +
                  'bestehend aus PE-Folie (überlappt und verklebt),\n' +
                  'Holzwerkstoffplatten 25mm (Nut-Feder-System),\n' +
                  'elastischer, heterogener PVC-Bodenbelag nach\n' +
                  'EN 651 mit werksseitigem Oberflächenschutz.\n' +
                  '250-300 cm breit, Dicke 2,4 mm, Brandverhalten\n' +
                  'Cfl-s1, Umweltinformationen: Low emission (TVOC\n' +
                  'nach 28 Tagen: <100 µg/m³) für ein optimales \n' +
                  'Raumklima, Farbe: D42130 grau, PVC-Sockelleiste\n' +
                  '(schwarz), Belastung: 2,0 kN/m², inkl.\n' +
                  'Abdeckwinkel im Türbereich\n'
              },
              {
                value: 'floor_drypaint',
                label: 'Boden gedämmt',
                img: 'assets/images/tc/floor/dry.jpg',
                info: '<h3>Boden gedämmt</h3>' +
                  'bestehend aus PE-Folie (überlappt und verklebt),\n' +
                  'Polystyrol-Dämmplatten 80mm,\n' +
                  'Holzwerkstoffplatten 18mm (Nut-Feder-System),\n' +
                  'elastischer, heterogener PVC-Bodenbelag nach\n' +
                  'EN 651 mit werksseitigem Oberflächenschutz.\n' +
                  '250-300 cm breit, Dicke 2,4 mm, Brandverhalten\n' +
                  'Cfl-s1, Umweltinformationen: Low emission (TVOC\n' +
                  'nach 28 Tagen: <100 µg/m³) für ein optimales \n' +
                  'Raumklima, Farbe: D42130 grau, PVC-Sockelleiste\n' +
                  '(schwarz), Belastung: 2,0 kN/m²,\n' +
                  'inkl. Abdeckwinkel im Türbereich\n'
              }
            ]
          }
        ]
      },
      {
        headline: 'Elektronik & Schallschutz',
        formElements: [
          {
            type: 'checkbox-image',
            grid: 'col-12 col-mobile-landscape-6 col-ipad-landscape-3',
            name: 'epack',
            value: false,
            optional: true,
            validators: [],
            label: 'Elektro- und Beleuchtungspaket',
            content: {
              img: 'assets/images/tc/electro_acoustik/electro.jpg',
              info: '<h3>Elektro- und Beleuchtungspaket</h3>' +
                'nach VDE 0100, inklusive Installation/Messprotokoll, Leitungsschutzschalter, FI-Schutzschalter,\n' +
                'Abdeckstreifen, Stromschiene, Elektrokabel, Brüstungskanal, Leitungsführungskanal \n' +
                'auf Dach (für Elektrokabel der Beleuchtungskörper), Schalter, Steckdosen, \n' +
                'LED-Leuchten 40 Watt, Farbtemperatur: 4000 K, Lichtfarbe: neutralweiß, quadratische Form\n'
            }
          },
          {
            type: 'checkbox-image',
            grid: 'col-12 col-mobile-landscape-6 col-ipad-landscape-3',
            name: 'soundInsulation',
            value: false,
            optional: true,
            validators: [],
            label: 'Komplettpaket für erhöhten Schallschutz',
            content: {
              img: 'assets/images/tc/electro_acoustik/acoustics.jpg',
              info: '<h3>Erhöhtes Schallschutzpaket</h3>' +
                'R`w 36dB gemäß Prüfbericht 6556-1 nach ISO 140-5\n' +
                '(objektbezogene Abweichungen sind möglich)\n' +
                'bestehend aus nachfolgenden Ausführungsmerkmalen:\n' +
                '<ol>' +
                '<li>Wand-/Tür- und Dachelemente mit Steinwollfüllung (hohe spezifische Dichte/Eigengewicht)</li>' +
                '<li>Schallschutz-Dichtungspaket mit speziellen schallabsorbierenden Fugendichtbändern, eingesetzt zwischen Wand/Wand und Wand/Dach zur formschlüssigen Schallschutzverbindung der einzelnen Bauteile</li>' +
                '<li>Schallschutz-Doppelverglasung 26mm</li>' +
                '<li>Schallschutz-Türbodendichtung</li>' +
                '<li>Akustik-Abhangdecke als zusätzliche Schalldämmung des Daches sowie zur Optimierung der Raumakustik</li>' +
                '</ol>' +
                'Beim Konfigurieren der Standard-Wandelementhöhe 2.600mm, wird aufgrund der Akustik-Abhangdecke eine Wandelementhöhe von 2.850mm berücksichtigt\n'
            }
          }
        ]
      },
      {
        headline: 'Lüftung, Heizung & Klima',
        formElements: [
          {
            type: 'checkbox-image',
            grid: 'col-12 col-mobile-landscape-6 col-ipad-landscape-3',
            name: 'wallfan',
            value: false,
            optional: true,
            validators: [],
            label: 'Elektro-Wandentlüfter',
            content: {
              img: 'assets/images/tc/clima/wallfan.jpg',
              info: '<h3>Elektro-Wandentlüfter</h3>' +
                'mit Kiemenblech als natürliche Nachströmöffnung innen und außen, Förderleistung \n' +
                'freiblasend: 75-150 m³/h, Dimensionierung nach DIN EN 13779,\n' +
                'Schalldruckpegel dB(A): 25 dB (Laborwert), inkl. EIN/AUS-Schalter\n'
            }
          },
          {
            type: 'checkbox-image',
            grid: 'col-12 col-mobile-landscape-6 col-ipad-landscape-3',
            name: 'climacompact',
            value: false,
            optional: true,
            validators: [],
            label: 'Klima-Kompaktanlage',
            content: {
              img: 'assets/images/tc/clima/clima.jpg',
              info: '<h3>Kompakt-Klimaanlage</h3>' +
                'inklusive Infrarot-Fernbedienung zur Temperaturregelung, Timer-Funktion, bodenstehend, Kondenswasserabführung durch Abluft nach außen, Nennleistung im Kühlbetrieb: 2,3 kW,\n' +
                'Energieeffizienzklasse: A, Schalldruckpegel 1 m Abstand: 36 - 43 dB(A), Arbeitsbereich Kühlen min./max. +20 / +35 °C, Kältemittel R410 A, inkl. separat abgesicherter Steckdose\n'
            }
          },
          {
            type: 'checkbox-image',
            grid: 'col-12 col-mobile-landscape-6 col-ipad-landscape-3',
            name: 'convector',
            value: false,
            optional: true,
            validators: [],
            label: 'Elektro-Wandkonvektor',
            content: {
              img: 'assets/images/tc/clima/wallconvector.jpg',
              info: '<h3>Elektro-Wandkonvektor</h3>' +
                'wandhängend, Heizleistung: 2.000 Watt, inkl. separat abgesicherter Steckdose\n'
            }
          }
        ]
      }
    ]
  },
  {
    headline: 'Ihr vorläufiger Richtpreis inkl. Optionen',
    formSections: [
      {
        customArea: 'reporting-price-offer'
      }
    ]
  }
];

export const trendlineConfiguratorPdfData: TrendlineConfiguratorFormSectionModel[] = [
  {
    headline: 'Richtpreisangebot per E-Mail',
    subline: 'Vielen Dank für Ihr Interesse. Das Richtpreisangebot inklusive aller Zeichnungen wird Ihnen in Kürze per E-Mail zugestellt.',
  },
  {
    formElements: [
      {
        type: 'input',
        grid: 'col-12 col-ipad-portrait-4',
        placeholder: '',
        variant: 'text',
        name: 'company_name',
        validators: ['required'],
        label: 'Firma'
      },
      {
        type: 'select',
        grid: 'col-12 col-ipad-portrait-4',
        name: 'salutation',
        label: 'Anrede',
        validators: ['required'],
        value: {
          value: true,
          label: 'Frau'
        },
        content: [
          {
            value: true,
            label: 'Frau'
          },
          {
            value: false,
            label: 'Herr'
          }
        ]
      },
      {
        type: 'input',
        grid: 'col-12 col-ipad-portrait-4',
        placeholder: '',
        variant: 'text',
        name: 'name',
        validators: ['required'],
        label: 'Vorname, Name'
      },
      {
        type: 'input',
        grid: 'col-12 col-ipad-portrait-4',
        placeholder: '',
        variant: 'text',
        name: 'company_street',
        validators: ['required'],
        label: 'Straße',
        value: null
      },
      {
        type: 'input',
        grid: 'col-12 col-ipad-portrait-4',
        placeholder: '',
        variant: 'tel',
        name: 'company_zip_code',
        validators: ['required', {minLength: 5}, {maxLength: 5}],
        label: 'PLZ',
        value: null
      },
      {
        type: 'input',
        grid: 'col-12 col-ipad-portrait-4',
        placeholder: '',
        variant: 'text',
        name: 'company_city',
        validators: ['required'],
        label: 'Ort'
      },
      {
        type: 'input',
        grid: 'col-12 col-ipad-portrait-4',
        placeholder: '',
        variant: 'tel',
        name: 'phonenumber',
        validators: ['required'],
        label: 'Telefon'
      },
      {
        type: 'input',
        grid: 'col-12 col-ipad-portrait-4',
        placeholder: '',
        variant: 'email',
        name: 'email',
        label: 'E-Mail',
        validators: ['required', {pattern: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'}]
      }
    ]
  },
  {
    formElements: [
      {
        grid: 'col-12 col-ipad-portrait-6 col-desktop-smallest-4',
        label: 'Ich habe den Hinweis zum Datenschutz gelesen und akzeptiere diesen. Ihre Daten werden zur Abwicklung Ihrer Anfrage sowie zur zweckbezogenen Betreuung verarbeitet und genutzt',
        name: 'agreement',
        type: 'checkbox',
        validators: ['requiredTrue'],
        value: false
      }
    ],
    submitButton: {
      label: 'Richtpreisangebot jetzt abrufen',
      action: 'save'
    }
  }
];

export const navData = [
  '01 Ausführungsvarianten',
  '02 Abmessungen',
  '03 Raumaufteilung',
  '04 Preis & Zeichnung',
  '05 Optionen',
  '06 Richtpreisangebot als PDF'
];

export const installationCosts = {
  workPerHour: 36,
  safetyBelt: 16,                     // Sicherheitsgurte
  prices: {
    podest: {                         // Podest
      price: 1967,
      workAmount: 3
    },
    pillar: [                         // Stütze (bis 2 Stockwerke)
      {
        price: 200, workAmount: 2.5
      },
      {
        price: 259, workAmount: 2.5
      }
    ],
    beam: [                           // Unterzug (bis 2 Stockwerke),
      {
        price: 58, workAmount: .5
      },
      {
        price: 121, workAmount: .5
      },
    ],
    partitionPillar: {                // Trennwandstütze
      price: 58, workAmount: 2.5
    },
    roof: {                           // Dach
      price: 92, workAmount: .45
    },
    suspendingCeiling: {              // Zwischendecke,
      price: 222, workAmount: .45
    },
    roofCapAngle: {                   // Dachabschlusswinkel
      price: 19, workAmount: .1
    },
    cleaderAngle: {                   // Verkleidungswinkel,
      price: 12, workAmount: .25
    },
    ceilingSlab: {                    // erweitertes Deckenauflager
      price: 14, workAmount: .25
    },
    stage: [{price: 935, workAmount: 0}, {price: 505, workAmount: 0}, {price: 450, workAmount: 0}],
    2600: {
      solidWall: {                    // Vollwandelement
        price: 211, workAmount: 1
      },
      solidFillerWall: {               // Pass-Vollwandelement
        price: 219, workAmount: 1
      },
      glassWall: {                    // Glasswandelement
        price: 233, workAmount: 1
      },
      glassFillerWall: {              // Pass-Glaswandelement
        price: 246, workAmount: 1
      },
      door: {                         // Einzeltürelement
        price: 991, workAmount: 1
      },
      cornerProfile: {                // Eckprofil
        price: 27, workAmount: .5
      },
      hollowProfile: {                // Hohlprofil
        price: 47, workAmount: .5
      },
      wallConnectionProfile: {        // Wandanschlussprofil
        price: 17, workAmount: .5
      },
      stairway: {                     // Treppe
        price: 2861, workAmount: 6
      }
    },
    2850: {
      solidWall: {
        price: 230, workAmount: 1
      },
      solidFillerWall: {
        price: 238, workAmount: 1
      },
      glassWall: {
        price: 290, workAmount: 1
      },
      glassFillerWall: {
        price: 309, workAmount: 1
      },
      door: {
        price: 1009, workAmount: 1
      },
      cornerProfile: {
        price: 28, workAmount: .5
      },
      hollowProfile: {
        price: 44, workAmount: .5
      },
      wallConnectionProfile: {
        price: 18, workAmount: .5
      },
      stairway: {
        price: 3015, workAmount: 6
      }
    },
    3100: {
      solidWall: {
        price: 248, workAmount: 1
      },
      solidFillerWall: {
        price: 257, workAmount: 1
      },
      glassWall: {
        price: 309, workAmount: 1
      },
      glassFillerWall: {
        price: 328, workAmount: 1
      },
      door: {
        price: 1028, workAmount: 1
      },
      cornerProfile: {
        price: 30, workAmount: .5
      },
      hollowProfile: {
        price: 55, workAmount: .5
      },
      wallConnectionProfile: {
        price: 20, workAmount: .5
      },
      stairway: {
        price: 3407, workAmount: 6
      }
    },
    4000: {
      solidWall: {
        price: 332, workAmount: 1.2
      },
      solidFillerWall: {
        price: 341, workAmount: 1.2
      },
      glassWall: {
        price: 384, workAmount: 1.2
      },
      glassFillerWall: {
        price: 403, workAmount: 1.2
      },
      door: {
        price: 1094, workAmount: 1.2
      },
      cornerProfile: {
        price: 30, workAmount: .5
      },
      hollowProfile: {
        price: 79, workAmount: .5
      },
      wallConnectionProfile: {
        price: 24, workAmount: .5
      },
      stairway: {
        price: 0, workAmount: 0
      }
    },
    options: {
      floorWood: 101,
      drypaint: 146,
      epack: [212.60, 191.16, 189, 184],
      soundInsulation: {
        2600: {
          solidWall: 90,
          solidFillerWall: 90,
          glassWall: 183,
          glassFillerWall: 189,
          door: 264,
          acousticCeiling: 50.40
        },
        2850: {
          solidWall: 71,
          solidFillerWall: 71,
          glassWall: 126,
          glassFillerWall: 126,
          door: 246,
          acousticCeiling: 50.40
        },
        3100: {
          solidWall: 73,
          solidFillerWall: 73,
          glassWall: 128,
          glassFillerWall: 128,
          door: 246,
          acousticCeiling: 50.40
        },
        4000: {
          solidWall: 81,
          solidFillerWall: 81,
          glassWall: 136,
          glassFillerWall: 136,
          door: 256,
          acousticCeiling: 50.40
        }
      },
      wallfan: {
        price: 597,
        factor: 5,
        division: 150
      },
      climacompact: {
        price: 2205,
        factor: 40,
        division: 2500
      },
      convector: {
        price: 328,
        factor: 40,
        division: 2000
      }
    }
  }
};
