import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { navData, trendlineConfiguratorData, trendlineConfiguratorPdfData } from './trendline-configurator-data';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { TrendlineConfiguratorPriceInterface, TrendlineConfiguratorProduct } from './trendline-configurator.model';
import { HttpClient } from '@angular/common/http';
import { TrendlineConfiguratorProductService } from './services/trendline-configurator-product.service';
import { TrendlineConfiguratorPriceCalculationService } from './services/trendline-configurator-price-calculation.service';
import { Router } from '@angular/router';
import { XcoApiService, XcoFormBuilderService, XcoScrollToService } from '@conception/ng-xava-connect';
import { NgxImageCompressService } from 'ngx-image-compress';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [
    XcoFormBuilderService,
    XcoScrollToService,
    TrendlineConfiguratorProductService,
    TrendlineConfiguratorPriceCalculationService
  ]
})
export class AppComponent implements OnInit, AfterViewInit {
  @Input() data = trendlineConfiguratorData;
  @Input() pdfData = trendlineConfiguratorPdfData;
  @ViewChild('navContainer', { static: true }) navContainer: ElementRef;
  @ViewChild('messages', {static: true}) messages: ElementRef;
  state = 0;
  emitBlueprintUpdate: Subject<void> = new Subject<void>();
  showResults: Subject<any> = new Subject<any>();
  nav = navData;
  formElements = [];
  form: FormGroup;
  pdfForm: FormGroup;
  pdfFormElements = [];
  isBrowser;
  costs: any;
  price: TrendlineConfiguratorPriceInterface;
  projectSubline: any;
  activeNav = 0;
  enabledAfterSubmit = 2;
  hideNav: boolean;
  infoContent: string = null;
  updatedViews: any;
  emailImages: {topView: [], sideView: []};
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private _api: XcoApiService,
    private _http: HttpClient,
    private _service: XcoFormBuilderService,
    private _trendlineConfigurator: TrendlineConfiguratorProductService,
    private _priceCalculator: TrendlineConfiguratorPriceCalculationService,
    private _router: Router,
    private _scrollService: XcoScrollToService,
    private _product: TrendlineConfiguratorProduct,
    private compressService: NgxImageCompressService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  @HostListener('window:scroll')
  onWindowScroll() {
    this.headerIsCompact();
    this.setActiveNav();
  }

  ngOnInit() {
    this.price = {
      shipping: 0,
      loadmeter: 0,
      material: 0,
      options: 0,
      optionAmount: {
        wallfan: 0,
        climacompact: 0,
        convector: 0
      },
      montage: 0,
      withoutOptions: 0,
      optionsOnly: 0,
      overall: 0
    };
    this._api.getData(environment.endpoints.trendlinePrice).subscribe((data) => {
      this.costs = data;
    });
    // this.costs = installationCosts;

    if (this.isBrowser) {
      this.data.forEach(section => section.formSections.forEach(form => this.formElements.push(form)));
      this.pdfData.forEach(form => this.pdfFormElements.push(form));
      this.setFormElements();
      this.form.valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      ).subscribe(value => {
        this._trendlineConfigurator.createProductObject(value);
        this.price = this._priceCalculator.getPrice(this.costs);
        this.roundPriceValues();
      });
      this.form.controls['project_name'].valueChanges.subscribe( (value) => {
        this.projectSubline = 'Ihr Projekt ' + value + ' kann mit diesen Ausstattungsoptionen erweitert bzw. ergänzt werden. Die aufgezählten Ausstattungsmerkmale stellen die Standard-Optionen dar. Darüber hinaus können Sonderausstattungen angeboten werden (außerhalb des Konfigurators)';
      });
    }
  }

  private roundPriceValues() {
    for (const prop in this.price) {
      if (this.price.hasOwnProperty(prop) && typeof this.price[prop] === 'number') {
        this.price[prop] = Math.floor(this.price[prop] / 100) * 100;
      }
    }
  }

  ngAfterViewInit() {
    this.showResults.subscribe(() => {
      setTimeout( () => {
        this._scrollService.scrollTo(
          document.querySelector('#tc-formsection-03'),
          '.header--sticky',
          500);
      }, 1000);
    });
  }

  reloadPage() {
    if (this.isBrowser) { location.reload(); }
  }

  headerIsCompact() {
    if (this.isBrowser) {
      const offsetElement = this.navContainer.nativeElement;
      return window.pageYOffset >= this._scrollService.getOffsetTop(offsetElement);
    }
  }

  setActiveNav() {
    if (this.isBrowser) {
      const navSection = document.querySelectorAll('[id^="tc-formsection"]');
      const docked = this._scrollService.getDockedElm('.header--sticky');
      const offsetNav = this.navContainer.nativeElement.querySelector('.horizontal-navigation');
      navSection.forEach((elm: Element, index) => {
        if (index > this.enabledAfterSubmit) { return; }
        if (window.pageYOffset + ((docked?.clientHeight) ? docked.clientHeight : 0) >= this._scrollService.getOffsetTop(elm) - offsetNav.clientHeight) {
          this.activeNav = index;
        }
      });
    }
  }

  setFormElements() {
    this.form = this._service.getFormElements(this.formElements);
    this.pdfForm = this._service.getFormElements(this.pdfFormElements);
  }

  updateBlueprints() {
    this.emitBlueprintUpdate.next();
  }

  onSubmit(action: string) {
    this._service.markFormControlsAsTouched(this.form);
    if (action === 'calc') {
      this.showResults.next();
      this.enabledAfterSubmit = navData.length - 1;
    }

    if (action === 'save') {
      this.updateBlueprints();
    }

    // ToDO: receive success- or fail-message
    if (action === 'submit') {
      this.state = 1;
      this.compressImagesForEmailPostRequest().then(() => {
        const submitEmailObject = {
          finisher: 'trendline_email',
          data: {
            product: this.createProductObjectForPostRequest(),
            price: this.price,
            formData: {...this.form.value, ...this.pdfForm.value}
          }
        };

        this._http.post(environment.endpoints.form, submitEmailObject)
          .subscribe(data => {
            this.state = 2;
          });
      });
    }
  }

  private createProductObjectForPostRequest(): TrendlineConfiguratorProduct {
    const productObjectForPostRequest = Object.assign({}, this._product);
    productObjectForPostRequest.blueprintImages = this.emailImages;
    return productObjectForPostRequest;
  }

  private compressImagesForEmailPostRequest() {
    let countProcessed = 0;
    const imgLength = this.updatedViews ? this.updatedViews.topView.length + this.updatedViews.sideView.length :
      this._product.blueprintImages.topView.length + this._product.blueprintImages.sideView.length;

    this.emailImages = {
      topView: [],
      sideView: []
    };

    return new Promise((resolve) => {
      for (const view in (this.updatedViews ? this.updatedViews : this._product.blueprintImages)) {
        if (this._product.blueprintImages.hasOwnProperty(view)) {
          this._product.blueprintImages[view].forEach(img => {
            this.compressService.compressFile(img.img, 0, 50).then(result => {
              this.emailImages[view].push({'name': img.name, 'img': result});
              countProcessed++;
              if (countProcessed === imgLength) {
                resolve();
              }
            });
          });
        }
      }
    });
  }
}
