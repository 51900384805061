<app-header></app-header>
<section class="intro">
  <h1 [innerHTML]="'Der Hallenbüro-Konfigurator'"></h1>
  <h2 [innerHTML]="'Das online Planungstool für Ihren neuen Systemraum TRENDLINE – einfach, schnell und direkt zu Ihrem Richtpreisangebot'"></h2>
</section>
<div class="content-wrap">
  <section class="horizontal-navigation__container" #navContainer>
    <ng-container *ngIf="state === 0">
      <ul class="horizontal-navigation"
          [hidden]="hideNav"
          [class.horizontal-navigation--fixed]="headerIsCompact()">
        <li *ngFor="let category of nav; let i = index;">
          <div class="horizontal-navigation__item"
               [xcoScrollto]="'#tc-formsection-0' + i"
               [xcoScrolltoFixed]="'.header--sticky'"
               [class.horizontal-navigation__item--previous]="activeNav > i"
               [class.horizontal-navigation__item--active]="activeNav === i"
               [class.horizontal-navigation__item--disabled]="enabledAfterSubmit < i"
               (click)="setActiveNav()"
               [innerHTML]="category"></div>
        </li>
      </ul>
    </ng-container>
  </section>
  <div *ngIf="state === 0" class="section">
    <form *ngIf="costs" [formGroup]="form">
      <div *ngFor="let section of data; let i = index;" [hidden]="i > enabledAfterSubmit " [id]="'tc-formsection-0' + i">
        <app-pull-down class="pull-down--no-top" *ngIf="i > 0"></app-pull-down>
        <h3 class="align-center"
            [innerHTML]="'0' + (i + 1) + ' - ' + section.headline"></h3>
        <div class="wrap">
          <div *ngIf="section.subline" class="col-12">
            <p class="align-center" [innerHTML]="section.subline"></p>
          </div>
          <div *ngIf="section.projectSubline" class="col-12">
            <p class="align-center" [innerHTML]="projectSubline"></p>
          </div>
          <div class="col-12 no-gutters">
            <ng-container *ngFor="let formSection of section.formSections;">
              <app-pull-down class="pull-down--no-bottom" *ngIf="formSection.pulldown"></app-pull-down>
              <h6 class="with-decor" [class.with-decor--topspace]="formSection.topspace" *ngIf="formSection.headline" [innerHTML]="formSection.headline"></h6>
              <p class="align-center bodytext" *ngIf="formSection.subline" [innerHTML]="formSection.subline"></p>
              <div class="form-section" *ngIf="formSection.formElements">
                <trendline-configurator-section class="wrap full-height horizontal-center"
                                                (infoContent)="infoContent = $event"
                                                [data]="formSection.formElements"
                                                [form]="form">
                  <ng-template trendlineConfiguratorSectionRange
                               let-content="content"
                               let-value="value"
                               let-name="name">
                    <ranger-input [rangeFormControl]="form.controls[name]"
                                  [options]="content[form.controls['flanks'].value - 1]"
                                  [steps]="form.controls['grid_unit'].value.value === 'grid'"></ranger-input>
                  </ng-template>
                  <ng-template trendlineConfiguratorSectionSelect
                               let-name="name"
                               let-placeholder="placeholder"
                               let-id="labelForId"
                               let-items="items">
                    <ng-select class="select-dropdown select-dropdown--gray"
                               [items]="items"
                               [labelForId]="id"
                               bindLabel="label"
                               bindName="value"
                               [clearable]="false"
                               [formControlName]="name"
                               [placeholder]="placeholder"></ng-select>
                  </ng-template>
                  <ng-template trendlineConfiguratorSectionCheckboxIcon>
                    <i class="checkbox__icon" [inlineSVG]="'checked.svg'"></i>
                  </ng-template>
                  <ng-template trendlineConfiguratorSectionRadioIcon>
                    <i class="radio__icon"></i>
                  </ng-template>
                  <ng-template trendlineConfiguratorSectionButtonIcon>
                    <i [className]="'svg-icon svg-icon--white'"
                       [inlineSVG]="'simple-arrow.svg'"></i>
                  </ng-template>
                </trendline-configurator-section>
              </div>
              <ng-container *ngIf="formSection.customArea"
                            [ngSwitch]="formSection.customArea">
                <tc-chosen-dimensions [form]="form"
                                      *ngSwitchCase="'chosen-dimensions'"></tc-chosen-dimensions>
                <tc-room-division [form]="form"
                                  *ngSwitchCase="'room-division'">
                  <ng-template trendlineConfiguratorRoomDivisionSelect
                               let-name="name"
                               let-id="labelForId"
                               let-items="items">
                    <ng-select class="select-dropdown select-dropdown--gray"
                               [items]="items"
                               [labelForId]="id"
                               bindLabel="label"
                               bindName="value"
                               [clearable]="false"
                               [formControlName]="name"></ng-select>
                  </ng-template>
                </tc-room-division>
                <tc-reporting-price-offer [form]="form"
                                          [optional]="formElements"
                                          [price]="price"
                                          *ngSwitchCase="'reporting-price-offer'">
                </tc-reporting-price-offer>

                <tc-blueprint [price]="price"
                              (overlayOpened)="hideNav = $event"
                              [form]="form"
                              [showResults]="showResults"
                              [emitViewsUpdate]="emitBlueprintUpdate.asObservable()"
                              (updateFinish)="onSubmit('submit')"
                              (updatedViews)="updatedViews = $event"
                              *ngSwitchCase="'blueprint'">
                </tc-blueprint>

              </ng-container>
              <div class="form-section form-section--no-top-margin">
                <h6 class="with-decor with-decor--button" *ngIf="formSection.submitButton">
                  <button (click)="onSubmit(formSection.submitButton.action)"
                          class="button button--configurator"
                          [disabled]="form.invalid === true"
                          [innerHTML]="formSection.submitButton.label"></button>
                </h6>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
    <form *ngIf="costs" [formGroup]="pdfForm" [hidden]="data.length - 1 > enabledAfterSubmit" id="form-costs">
      <div class="wrap">
        <div class="col-12 no-gutters">
          <ng-container *ngFor="let formSection of pdfData;">
            <h6 class="with-decor"
                [class.with-decor--topspace]="formSection.topspace"
                *ngIf="formSection.headline"
                [innerHTML]="formSection.headline"></h6>
            <p class="align-center bodytext"
               *ngIf="formSection.subline"
               [innerHTML]="formSection.subline"></p>
            <div class="form-section"
                 *ngIf="formSection.formElements">
              <trendline-configurator-section class="inner-wrap full-height horizontal-center"
                                              [data]="formSection.formElements"
                                              [form]="pdfForm">
                <ng-template trendlineConfiguratorSectionSelect
                             let-name="name"
                             let-id="labelForId"
                             let-items="items">
                  <ng-select class="select-dropdown select-dropdown--gray"
                             [items]="items"
                             [labelForId]="id"
                             bindLabel="label"
                             bindName="value"
                             [clearable]="false"
                             [formControlName]="name"></ng-select>
                </ng-template>
                <ng-template trendlineConfiguratorSectionCheckboxIcon>
                  <i class="checkbox__icon" [inlineSVG]="'checked.svg'"></i>
                </ng-template>
                <ng-template trendlineConfiguratorSectionRadioIcon>
                  <i class="radio__icon"></i>
                </ng-template>
                <ng-template trendlineConfiguratorSectionButtonIcon>
                  <i [className]="'svg-icon svg-icon--white'"
                     [inlineSVG]="'simple-arrow.svg'"></i>
                </ng-template>
              </trendline-configurator-section>
            </div>
            <div class="form-section">
              <h6 class="with-decor with-decor--button" *ngIf="formSection.submitButton">
                <button (click)="$event.preventDefault(); onSubmit(formSection.submitButton.action)"
                        class="button button--configurator"
                        [disabled]="pdfForm.invalid === true"
                        [innerHTML]="formSection.submitButton.label"></button>
              </h6>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <div class="align-center">
      <a class="tc-reset-button" (click)="reloadPage()" [innerHTML]="'Neue Konfiguration starten'"></a>
    </div>
  </div>
  <div #messages>
    <ng-container *ngIf="state != 0">
      <div class="wrap">
        <div class="col-12">
          <hr>
          <ng-container *ngIf="state === 1">
            <h3 class="centered-headline">Formulardaten werden übertragen</h3>
            <div class="contact-zip-search__loader" [inlineSVG]="'preloader-small.svg'"></div>
          </ng-container>

          <ng-container *ngIf="state === 2">
            <h3 class="centered-headline">Vielen Dank für Ihr Interesse am Systemraum TRENDLINE.</h3>
            <h4 class="centered-headline">Sie erhalten in Kürze die gewünschten Unterlagen per E-Mail.</h4>
          </ng-container>
          <hr>
          <div class="tc-reset-button__container">
            <a class="tc-reset-button" (click)="reloadPage()" [innerHTML]="'Neue Konfiguration starten'"></a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="popup-infobox" *ngIf="infoContent !== null;">
    <div class="popup-infobox__close svg-icon svg-icon--white" (click)="infoContent = null" [inlineSVG]="'close.svg'"></div>
    <div class="popup-infobox__content-container">
      <div class="popup-infobox__info-icon svg-icon svg-icon--blue" [inlineSVG]="'info.svg'"></div>
      <div class="popup-infobox__content" [innerHTML]="infoContent"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
