import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[trendlineConfiguratorSectionButtonIcon]' })
export class TrendlineConfiguratorSectionButtonIconDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[trendlineConfiguratorSectionSelect]' })
export class TrendlineConfiguratorSectionSelectDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[trendlineConfiguratorSectionCheckboxIcon]' })
export class TrendlineConfiguratorSectionCheckboxIconDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[trendlineConfiguratorSectionRadioIcon]' })
export class TrendlineConfiguratorSectionRadioIconDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[trendlineConfiguratorSectionRange]' })
export class TrendlineConfiguratorSectionRangeDirective {
  constructor(public template: TemplateRef<any>) { }
}
