import {Injectable} from '@angular/core';
import {installationCosts} from '../trendline-configurator-data';
import {
  TrendlineConfiguratorProduct,
  TrendlineConfiguratorSingleFloor,
  TrendlineConfiguratorPriceInterface,
  TrendlineConfiguratorSingleItemCostsInterface
} from '../trendline-configurator.model';
import {XcoApiService} from '@conception/ng-xava-connect';
import { environment } from '../../environments/environment';

@Injectable()
export class TrendlineConfiguratorPriceCalculationService {
  installationCosts;
  price: TrendlineConfiguratorPriceInterface;
  // ToDo Google Distance Matrix API Implementation
  distance = 379;
  squareMeter: number;
  // ToDo zipCode is not dynamicly change at the moment
  zipCode = 38;
  workHoursAmount;
  constructor(
    private _product: TrendlineConfiguratorProduct,
    private _api: XcoApiService
    ) {
  }
  getPrice(costs) {
    this.installationCosts = costs;
    this.price = {
      shipping: 0,
      loadmeter: 0,
      material: 0,
      options: 0,
      optionAmount: {
        wallfan: 0,
        climacompact: 0,
        convector: 0
      },
      montage: 0,
      withoutOptions: 0,
      optionsOnly: 0,
      overall: 0
    };
    this.workHoursAmount = 0;
    if (typeof this.installationCosts === 'undefined') {return; }

    this.squareMeter = (this._product.length / 1000) * (this._product.depth / 1000);
    this.floorSpecifics();

    if (this._product.roof) {
      this.setPillarBeam();
      this.roof();
      this.suspendingCeiling();
    }

    if (this._product.stage) { this.stage(); }
    if (this._product.stairway && !this._product.stage) { this.stairway(); }

    this.additionalCalculations();

    this.options();
    // this.getZipCode();
    this.price.montage  = this.montageCosts(this.workHoursAmount);
    this.shipping();
    this.setOutputPriceValues();
    return this.price;
  }

  setOutputPriceValues() {
    const obj = {
      withoutOptions: this.price.material + this.price.montage,
      optionsOnly: this.price.options,
      overall: this.price.material + this.price.montage + this.price.options
    };
    this.price = {...this.price, ...obj};
  }

  private getZipCode() {
    // if (this._product.zipCode.toString().length === 5) {
    //   this.zipCode = this._product.zipCode.toString().substr(0, 2);
    //   this.shipping();
    // } else {
    //   this.zipCode = '';
    //   this.price.shipping = 0;
    // }
  }

  private shipping() {
    this.price.shipping = 0;
    const shippingLoadMeter = this.price.loadmeter / 2;
    let obj; obj = {};

    if (shippingLoadMeter >= 12) {
      obj['whole'] = Math.floor(shippingLoadMeter / 12);
      obj['decimal'] = shippingLoadMeter - (obj['whole'] * 12);
    } else {
      obj['whole'] = 0;
      obj['decimal'] = shippingLoadMeter;
    }
    if (obj.decimal < 2) {
      const rounded = Math.round(obj.decimal * 2 ) / 2;
      obj.decimal = (obj.decimal > rounded) ? rounded + .5 : rounded;
    } else {
      obj.decimal = Math.ceil(obj.decimal);
    }

    if (obj.whole !== 0) {
      this.getShippingPrice(12, obj.whole);
    }

    if (!isNaN(obj.decimal)) {
      this.getShippingPrice(obj.decimal, 1);
    }
  }

  private getShippingPrice(load: number, factor: number) {
    this._api.getData(environment.endpoints.trendlineFreight + '?loadmeter=' + load + '&zip=' + this.zipCode).subscribe((data) => {
      this.price.shipping = Math.round(((data.cost * factor * 1.35)) / 100) * 100;
    });
  }

  private options() {
    this.optionFloorVariant();
    this.optionEpack();
    this.optionSoundInsulation();

    const roomVolumes = [];
    this._product.floors.forEach(floor => {
      const array = ['wallfan', 'climacompact', 'convector'];
      if (floor.innerRoomGridUnitsLength) {
        floor.innerRoomGridUnitsLength.forEach( (elm, index) => {
          let roomLength = elm * this._product.gridUnit;
          if (index === 0 || index === floor.innerRoomGridUnitsLength.length - 1) {
            roomLength += this._product.outerFillerLength;
          }

          if (this._product.flanks <= 2 && index === floor.innerRoomGridUnitsLength.length - 1 ) {
            roomLength += this._product.wallConnectionProfileUnit;
          }

          if (this._product.flanks <= 1 && index === 0 ) {
            roomLength += this._product.wallConnectionProfileUnit;
          }

          roomVolumes.push(((roomLength / 1000) * (this._product.depth / 1000)) * (floor.floorHeight / 1000));
        });
        roomVolumes.forEach(roomVolume => {
          array.forEach(option => this.optionVolumeCalc(option, roomVolume));
        });
      } else {
        array.forEach(option => this.optionVolumeCalc(option, this.squareMeter * (floor.floorHeight / 1000)));
      }
    });
  }

  private optionFloorVariant() {
    const price = this.installationCosts.prices.options;
    switch (this._product.options.floorVariant) {
      case 'floor_wood':
        this.price.options += price.floorWood * this.squareMeter;
        break;
      case 'floor_drypaint':
        this.price.options += price.drypaint * this.squareMeter;
        break;
    }
  }

  private optionEpack() {
    if (!this._product.options.epack) { return; }
    let price; price = this.installationCosts.prices.options.epack;
    if (this.squareMeter < 25) {
      price = price[0];
    } else if (this.squareMeter < 50 && this.squareMeter >= 25) {
      price = price[1];
    } else if (this.squareMeter < 100 && this.squareMeter >= 50) {
      price = price[2];
    } else if (this.squareMeter >= 100) {
      price = price[3];
    }
    this.price.options += price * this.squareMeter;
  }

  private optionSoundInsulation() {
    if (!this._product.options.soundInsulation) { return; }
    let costs;
    this._product.floors.forEach(floor => {
      costs = this.installationCosts.prices.options.soundInsulation[floor.floorHeight];
      for (const costsKey in costs) {
        if (costs.hasOwnProperty(costsKey) && costsKey !== 'acousticCeiling') {
          this.price.options += floor[costsKey] * costs[costsKey];
        }
      }
      this.price.options += this.squareMeter * costs['acousticCeiling'];
    });
  }

  private optionVolumeCalc(name: string, volume: number) {
    if (!this._product.options[name]) { return; }

    const costs = this.installationCosts.prices.options[name],
          items = Math.ceil( (volume * costs.factor) / costs.division );

    this.price.optionAmount[name] += items;
    this.price.options            += this.installationCosts.prices.options[name].price * items;
  }

  private addToMaterialAndMontageCosts(val: number, obj: TrendlineConfiguratorSingleItemCostsInterface) {
    this.price.material += val * obj.price;
    this.workHoursAmount += val * obj.workAmount;
  }

  private roof() {
    this.addToMaterialAndMontageCosts(this.squareMeter, this.installationCosts.prices['roof']);
    this.price.loadmeter += ((this.squareMeter / 4) / 10) * 5;
  }

  private suspendingCeiling() {
    if (this._product.floors.length > 1) {
      this.addToMaterialAndMontageCosts(this.squareMeter, this.installationCosts.prices['suspendingCeiling']);
      this.price.loadmeter += ((this.squareMeter / 4) / 10) * 5;
    }
  }

  private stage() {
    const stageIndex = this.squareMeter <= 30 ? 0 : this.squareMeter < 100 ? 1 : 2;
    this.price.material += this.installationCosts.prices.stage[stageIndex].price * this.squareMeter;
  }

  private floorSpecifics() {
    this._product.floors.forEach(floor => {
      this.singleFloor(floor);
    });
  }

  private singleFloor(floor: TrendlineConfiguratorSingleFloor) {
    let wallAmount: number,
        costs: any;
    costs                   = installationCosts.prices[floor.floorHeight];
    floor.solidWall         = floor.outerWall + floor.innerWall;
    floor.solidFillerWall   = floor.outerFillerWall + floor.innerFillerWall;
    floor.door              = floor.innerDoor + floor.outerDoor;
    // material

    // material + montage
    for (const costsKey in costs) {
      if (costs.hasOwnProperty(costsKey) && costsKey !== 'stairway') {
        this.addToMaterialAndMontageCosts(floor[costsKey], costs[costsKey]);
      }
    }

    // loadmeter
    wallAmount            = floor.solidWall + floor.solidFillerWall + floor.door + floor.glassWall + floor.glassFillerWall;
    this.price.loadmeter  += wallAmount / 12 * 3;

    // safetybelt-material costs
    this.price.material   += wallAmount * 2 * .095 * this.installationCosts.safetyBelt;
  }

  private additionalCalculations() {
    const array = ['partitionPillar', 'roofCapAngle', 'cleaderAngle', 'ceilingSlab'];
    const obj = {};

    array.forEach(key => {
      this._product.floors.forEach( floor => {
        if ( obj.hasOwnProperty('key')) {
          obj[key] += floor[key];
        } else {
          obj[key] = floor[key];
        }
      });

      if (key !== 'partitionPillar') {
        obj[key] = obj[key] / 1000;
      }
      this.addToMaterialAndMontageCosts(obj[key], this.installationCosts.prices[key]);
    });

    // flatrate for 'roofCapAngle', 'cleaderAngle', 'ceilingSlab'
    this.price.loadmeter += 3;

    this.partitionPillarLoadMeter(obj['partitionPillar']);
  }

  private stairway() {
    const stairway = this.installationCosts.prices[this._product.floors[0].floorHeight].stairway;
    if (stairway === undefined ) { return; }
    this.price.material   += stairway.price + this.installationCosts.prices.podest.price;
    this.price.montage    += this.montageCosts(stairway.workAmount + this.installationCosts.prices.podest.workAmount);
    this.price.loadmeter  += 5;
  }

  private setPillarBeam() {
    let pillarAmount = 0,
        beamAmount = 0;
    const variant = this._product.floors.length - 1,
          pillar  = this.installationCosts.prices.pillar[variant],
          beam    = this.installationCosts.prices.beam[variant];
    this._product.floors.forEach(floor => {
      pillarAmount  += floor.pillar;
      beamAmount    += floor.beam;
    });

    this.addToMaterialAndMontageCosts(pillarAmount, pillar);
    this.addToMaterialAndMontageCosts(beamAmount, beam);
    this.price.loadmeter += ( pillarAmount + (beamAmount / 4) ) / 10 * 4;
  }

  private partitionPillarLoadMeter(val) {
    this.price.loadmeter += val / 10 * 4;
  }

  private montageCosts(hours) {
    let   val: number;
    const work: number          = hours * installationCosts.workPerHour,
          days: number          = hours / 8 / 2,
          weeks: number         = Math.ceil(days / 5),
          nights: number        = weeks * 4 * 2,
          nightsCosts: number   = nights * 70,
          travel: number        = this.distance < 150 ? days * this.distance * 2 : weeks * this.distance * 2;
    if (this.distance < 150) {
      val = (work + travel) * 1.35;
    } else {
      val = (nightsCosts + travel + work) * 1.35;
    }
    return val;
  }

}
