import {Injectable} from '@angular/core';
import {
  TrendlineConfiguratorProduct,
  TrendlineConfiguratorSingleFloor
} from '../trendline-configurator.model';

@Injectable()
export class TrendlineConfiguratorProductService {
  formData: any;
  constructor(private _product: TrendlineConfiguratorProduct) {
  }

  createProductObject(form: object) {
    this.formData = form;
    this._product.flanks = this.formData.flanks;
    this.setFloorsLength();
    this.hasStage();
    this.hasStairway();
    this.hasRoof();
    this.setDimension(); // must be set before floors, cause it depends on it
    this.setFloors();
    this.setOverallHeight(); // must be set after floors, cause it depends on it
    this.setMaxRoomNumber();
    this.addOptions();
    this.setZipCode();
  }

  private hasStage() {
    this._product.stage = this.formData.variant === 'stage';
    this._product.stageHeight = this.formData.stageHeight;
  }

  private hasStairway() {
    this._product.stairway = this.formData.variant === 'stage' || this.formData.variant === '2g';

    if (this._product.stairway) {
      this.setStairwayData();
    }
  }

  private setZipCode() {
    this._product.zipCode = parseInt(this.formData['zip_code'], 10);
  }

  private addOptions() {
    this._product.options = {
      floorVariant: this.formData['floorVariant'],
      epack: this.formData['epack'],
      soundInsulation: this.formData['soundInsulation'],
      wallfan: this.formData['wallfan'],
      climacompact: this.formData['climacompact'],
      convector: this.formData['convector']
    };
  }

  private setStairwayData() {
    this._product.stairwayData = {};
    const height: number = this._product.stage ? this.formData.stageHeight + this._product.stagePodestHeight : this.formData.firstFloor + this._product.falseCeilingHeight;
    this._product.stairwayData.amount = Math.round(height / this._product.occursWide);
    this._product.stairwayData.slope = height / this._product.stairwayData.amount;
  }

  private setFloorsLength() {
    this._product.floorsLength = this.formData.variant === '2g' ? 2 : 1;
  }

  private setRoomSizePerFloor(i: number) {
    switch (i) {
      case 0:
        if (this.formData.hasOwnProperty('room_number_ff')) {
          return this.formData.room_number_ff.value;
        }
        break;
      case 1:
        if (this.formData.hasOwnProperty('room_number_sf')) {
          return this.formData.room_number_sf.value;
        }
    }
  }

  private hasRoof() {
    this._product.roof = this.formData.variant !== 'wallseparation';
  }

  private setDimension() {
    this._product.length = this.formData.length;
    this._product.depth = this.formData.depth;
  }

  private setOverallHeight() {
    let ceilingHeight = 200;
    switch (this.formData.variant) {
      case '1g':
        this._product.height = this._product.floors[0].floorHeight + this._product.roofHeight;
        break;
      case '2g':
        this._product.height = this._product.floors[0].floorHeight + this._product.floors[1].floorHeight + this._product.roofHeight + ceilingHeight;
        break;
      case 'wallseparation':
        this._product.height = this._product.floors[0].floorHeight;
        break;
      case 'stage':
        ceilingHeight = 400;
        this._product.height = this._product.floors[0].floorHeight + this._product.stageHeight + this._product.roofHeight + ceilingHeight;
        break;
    }
  }

  private setMaxRoomNumber() {
    this._product.maxRoom = Math.floor(this.formData.length / (this._product.gridUnit * 2));

    if (this._product.outerFillerLength > 0 && this._product.maxRoom > 1) {
      this._product.maxRoom = (this.formData.length / (this._product.gridUnit * 2) % 1 >= .5) ? this._product.maxRoom : this._product.maxRoom - 1;
    }
  }

  private setFloors () {
    let i; i = 0;
    this._product.floors = [];
    do {
      this._product.floors.push(this.singleFloor(i));
      i++;
    } while (i < this._product.floorsLength);

    this.setCeilingSlab();
  }

  private singleFloor(i: number): TrendlineConfiguratorSingleFloor {
    let obj = {
      floorHeight: 0,
      roomAmount: 0,
      outerWall: 0,
      outerFillerWall: 0,
      outerDoor: 1,
      glassWall: 0,
      glassFillerWall: 0,
      innerWall: 0,
      innerFillerWall: 0,
      wallConnectionProfile: 0,
      hollowProfile: 0,
      cornerProfile: 0,
      flooring: 0,
      cleaderAngle: 0,
      roofCapAngle: 0,
      falseCeiling: 0,
      ceilingSlab: 0,
      beam: 0,
      pillar: 0,
      partitionPillar: 0
    };

    obj.floorHeight = (i !== 1) ? this.formData.firstFloor : this.formData.secondFloor;
    obj = {...obj, ...this.setOuterWalls(obj)};
    obj.roomAmount = this.setRoomSizePerFloor(i);
    obj = {...obj, ...this.setInnerWalls(obj)};

    if (this._product.roof === true) {
      obj = {...obj, ...this.setCleaderAngle(obj)};
      obj = {...obj, ...this.setRoofCapAngle(obj)};
      obj = {...obj, ...this.setBeamSizeAndPillarAmount(obj)};
    } else {
      obj = {...obj, ...this.setPartitionPillar(obj)};
    }

    if (obj.roomAmount > 1) {
      obj = {...obj, ...this.countInnerRoomDivisions(obj)};
    }

    return obj;
  }

  private countInnerRoomDivisions(obj: TrendlineConfiguratorSingleFloor) {
    let wallConnectionProfileLength = 0,
        cornerProfileLength = 0;
    const roomDivisioned = [];

    if (this._product.flanks >= 3) {
      cornerProfileLength = 2;
    } else if (this._product.flanks === 2) {
      cornerProfileLength = 1;
      wallConnectionProfileLength = 1;
    } else if (this._product.flanks === 1) {
      wallConnectionProfileLength = 2;
    }
    let roughDivision = (this._product.length - (cornerProfileLength * this._product.cornerProfileUnit) - (wallConnectionProfileLength * this._product.wallConnectionProfileUnit) - (this._product.outerFillerLength * 2)) / this._product.gridUnit;

    let floorRoomAmount = obj.roomAmount;

    do {
      const result = roughDivision / floorRoomAmount;

      if (floorRoomAmount === 1) {
        roomDivisioned.push(Math.round(result));
        roughDivision = roughDivision - Math.round(result);
        floorRoomAmount = floorRoomAmount - 1;
      } else if (floorRoomAmount === 2) {
        roomDivisioned.push(Math.ceil(result));
        roomDivisioned.push(Math.floor(result));
        roughDivision = roughDivision - Math.ceil(result) - Math.floor(result);
        floorRoomAmount = floorRoomAmount - 2;
      } else {
        roomDivisioned.push(Math.round(result));
        roomDivisioned.push(Math.round(result));
        roughDivision = roughDivision - (Math.round(result) * 2);
        floorRoomAmount = floorRoomAmount - 2;
      }
    } while (roughDivision >= 2);
    obj.innerRoomGridUnitsLength = roomDivisioned;
    roomDivisioned.sort((a, b) => b - a);
    const last = roomDivisioned[roomDivisioned.length - 1];
    roomDivisioned.splice(-1, 1);
    roomDivisioned.unshift(last);

    return obj;
  }

  private setOuterWalls(obj: TrendlineConfiguratorSingleFloor): TrendlineConfiguratorSingleFloor {
    const lengthPieces = this.calcGridAndPassValues(this._product.length, 'l'),
          depthPieces = this.calcGridAndPassValues(this._product.depth, 'd');

    this._product.outerFillerLength = lengthPieces.fillerSize;
    this._product.outerFillerDepth = depthPieces.fillerSize;

    switch (this._product.flanks) {
      case 1:
        obj.glassWall = lengthPieces.solid;
        obj.glassFillerWall = lengthPieces.filler;
        if (lengthPieces.filler === 0) {
          --obj.glassWall;
          ++obj.outerWall;
        } else {
          --obj.glassFillerWall;
          ++obj.outerFillerWall;
        }
        break;
      case 2:
        obj.glassWall = lengthPieces.solid + depthPieces.solid;
        obj.glassFillerWall = lengthPieces.filler + depthPieces.filler;

        if (lengthPieces.filler === 0) {
          --obj.glassWall;
          ++obj.outerWall;
        } else {
          --obj.glassFillerWall;
          ++obj.outerFillerWall;
        }
        obj.cornerProfile = 1;
        break;
      case 3:
        obj.glassWall = lengthPieces.solid + (depthPieces.solid * 2);
        obj.glassFillerWall = lengthPieces.filler + (depthPieces.filler * 2);
        --obj.glassWall;
        ++obj.outerWall;
        obj.cornerProfile = 2;
        break;
      case 4:
        obj.outerWall = lengthPieces.solid;
        obj.outerFillerWall = lengthPieces.filler;
        obj.glassWall = lengthPieces.solid + (depthPieces.solid * 2);
        obj.glassFillerWall = lengthPieces.filler + (depthPieces.filler * 2);
        obj.cornerProfile = 4;
        break;
    }

    --obj.glassWall;
    obj.wallConnectionProfile = (this._product.flanks < 4) ? obj.wallConnectionProfile + 2 : obj.wallConnectionProfile;
    obj.hollowProfile = obj.wallConnectionProfile;
    return obj;
  }

  private setInnerWalls(obj: TrendlineConfiguratorSingleFloor): TrendlineConfiguratorSingleFloor {
    const depthPieces = this.calcGridAndPassValues(this._product.depth, 'i');
    const factor = obj.roomAmount - 1;
    this._product.innerFillersize = depthPieces.fillerSize;

    // inner door count
    obj.innerWall = factor * ( depthPieces.solid - 1);
    obj.innerFillerWall = factor * depthPieces.filler;
    obj.innerDoor = factor;
    obj.wallConnectionProfile += factor * 2;

    return obj;
  }

  private setBeamSizeAndPillarAmount(obj: TrendlineConfiguratorSingleFloor): TrendlineConfiguratorSingleFloor {
    let beamAmount;
    if (this._product.length < (this._product.gridUnit * 4)) {
      return obj;
    }

    beamAmount = this._product.length / 4200;

    if (this._product.flanks === 2) {
      beamAmount = beamAmount + 1;
    } else if (this._product.flanks === 1) {
      beamAmount = beamAmount + 2;
    }

    obj.beam = Math.ceil(beamAmount * this._product.depth / 1000);
    obj.pillar = Math.ceil(((this._product.depth / 5000) + 1) * beamAmount);
    return obj;
  }

  private setCleaderAngle(obj: TrendlineConfiguratorSingleFloor): TrendlineConfiguratorSingleFloor {
    switch (this._product.flanks) {
      case 1:
        obj.cleaderAngle = this._product.length + (this._product.depth * 2);
        break;
      case 2:
        obj.cleaderAngle = this._product.length + this._product.depth;
        break;
      case 3:
        obj.cleaderAngle = this._product.length;
        break;
    }
    return obj;
  }

  private setRoofCapAngle(obj: TrendlineConfiguratorSingleFloor): TrendlineConfiguratorSingleFloor {
    switch (this._product.flanks) {
      case 1:
        obj.roofCapAngle = this._product.length;
        break;
      case 2:
        obj.roofCapAngle = this._product.length + this._product.depth;
        break;
      case 3:
        obj.roofCapAngle = this._product.length + (this._product.depth * 2);
        break;
      case 4:
        obj.roofCapAngle = (this._product.length * 2) + (this._product.depth * 2);
        break;
    }
    return obj;
  }

  private setPartitionPillar(obj: TrendlineConfiguratorSingleFloor): TrendlineConfiguratorSingleFloor {
    let val; val = 0;
    switch (this._product.flanks) {
      case 4:
        val += (this._product.length * 2) + (this._product.depth * 2);
        break;
      case 3:
        val += this._product.length + (this._product.depth * 2);
        break;
      case 2:
        val += this._product.length + this._product.depth;
        break;
      case 1:
        val += this._product.length;
        break;
    }
    if (obj.roomAmount > 1) {
      val += (obj.roomAmount - 1) * (this._product.depth - (this._product.cornerProfileUnit * 2));
    }
    obj.partitionPillar = Math.ceil((val / (this._product.gridUnit * 3)) / 3);
    return obj;
  }

  private setCeilingSlab() {
    if (this._product.roof) {
      const roofFloor = this._product.floors[this._product.floorsLength - 1];
      roofFloor.ceilingSlab = (roofFloor.innerDoor + roofFloor.innerFillerWall + roofFloor.innerWall) * 2;
    }
  }

  private getWallConnectionProfileSizePerSide(type: string): number {
    let wallConnectionProfileSize;
    switch (type) {
      case 'l':
        if (this._product.flanks === 2) {
          wallConnectionProfileSize = this._product.wallConnectionProfileUnit;
        } else if (this._product.flanks === 1) {
          wallConnectionProfileSize = this._product.wallConnectionProfileUnit * 2;
        } else {
          wallConnectionProfileSize = 0;
        }
        break;
      case 'd':
        wallConnectionProfileSize = (this._product.flanks <= 3 && this._product.flanks > 1) ? this._product.wallConnectionProfileUnit : 0;
        break;
      case 'i':
        wallConnectionProfileSize = this._product.wallConnectionProfileUnit * 2;
        break;
    }
    return wallConnectionProfileSize;
  }

  private getCornerProfileSizePerSide(type: string): number {
    let cornerProfileSize;
    switch (type) {
      case 'l':
        if (this._product.flanks >= 3) {
          return cornerProfileSize = this._product.cornerProfileUnit * 2;
        } else if (this._product.flanks === 2) {
          return cornerProfileSize = this._product.cornerProfileUnit;
        } else {
          return cornerProfileSize = 0;
        }
      case 'd':
        if (this._product.flanks === 4) {
          return cornerProfileSize = this._product.cornerProfileUnit * 2;
        } else if (this._product.flanks === 3 || this._product.flanks === 2) {
          return cornerProfileSize = this._product.cornerProfileUnit;
        } else {
          return cornerProfileSize = 0;
        }
      case 'i':
        if (this._product.flanks === 4) {
          return cornerProfileSize = this._product.cornerProfileUnit * 2;
        } else {
          return cornerProfileSize = this._product.cornerProfileUnit;
        }
    }
  }

  private calcGridAndPassValues(val: number, type?: string) {
    let valueObj,
        num;
    const wallConnectionProfileSize = this.getWallConnectionProfileSizePerSide(type);
    const cornerProfileSize = this.getCornerProfileSizePerSide(type);
    const sideSize = val - wallConnectionProfileSize - cornerProfileSize;

    num = sideSize / this._product.gridUnit;
    valueObj = {
      solid: Math.floor(num),
      filler: (num % 1 !== 0) ? 2 : 0,
      fillerSize: this.calcFillerWallSize(num),
    };

    if (valueObj.filler === 2 && valueObj.fillerSize < this._product.passWallUnitMin) {
      --valueObj.solid;
      valueObj.fillerSize = this.calcSingleFillerWallSize(valueObj.fillerSize);
    }

    return valueObj;
  }

  private calcFillerWallSize(num: number): number {
    return ((num % 1 ) * this._product.gridUnit) / 2;
  }

  private calcSingleFillerWallSize(num: number): number {
    return num + (this._product.gridUnit / 2);
  }
}
