<ng5-slider #ranger
            [value]="rangeFormControl.value"
            (valueChange)="rangeFormControl.setValue($event)"
            [options]="options"
            [formControl]="rangeFormControl"
            [manualRefresh]="manualRefresh"></ng5-slider>
<div class="ng5-slider-handlebar-icon" [hidden]="!iconMoved" #handlebar>
  <i class="svg-icon svg-icon--white svg-icon--reversed" [inlineSVG]="'simple-arrow.svg'"></i>
  <i class="svg-icon svg-icon--white" [inlineSVG]="'simple-arrow.svg'"></i>
</div>
