<p class="align-center" [innerHTML]="data.subline[form.controls['variant'].value !== '2g' ? 0 : 1]"></p>
<div class="inner-wrap horizontal-center">
  <ng-container *ngFor="let elm of data.roomObject; let i = index;">
    <div [class]="elm.grid"
         *ngIf="i < _product.floors.length">
      <div [class]="'form-image form-image--select'">
        <span [className]="'form-element__container form-image__container'"
              [style.background-image]="'url(' + elm.img + ')'">
          <span *ngIf="form.controls['variant'].value === '2g'" class="form-image__top-label" [innerHTML]="i === 0 ? 'EG' : 'OG'"></span>
          <label [className]="'form-element__label'"
                 [attr.for]="'tc' + elm.type + '-' + elm.name"
                 [innerHTML]="form.controls['variant'].value === '2g' ? data.roomLabel + elm.label : data.roomLabel"></label>
          <div class="select-dropdown__container">
            <ng-template #defaultSelectTemplate>
              <select [id]="'tc' + elm.type + '-' + elm.name"
                      [class]="'select-dropdown'"
                      [formControlName]="elm.name">
                <option *ngFor="let item of elm.content;"
                        [value]="item.value"
                        [innerHTML]="item.label"></option>
              </select>
            </ng-template>
            <ng-template
              [ngTemplateOutlet]="selectTemplate || defaultSelectTemplate"
              [ngTemplateOutletContext]="{ name: elm.name, items: elm.content, labelForId: elm.type + '-' + elm.name}"></ng-template>
          </div>
        </span>
      </div>
    </div>
  </ng-container>
</div>
