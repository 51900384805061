import { AppComponent } from '../app.component';
import { PreloaderComponent } from '../shared/preloader/preloader.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';

export const contentElements = {
    content: {
        'trendline-configurator': AppComponent,
    },
    preloader: PreloaderComponent,
    pageNotFound: PageNotFoundComponent
};
