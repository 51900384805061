import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'tc-chosen-dimensions',
  templateUrl: './trendline-configurator-chosen-dimensions.component.html',
  styles: []
})
export class TrendlineConfiguratorChosenDimensionsComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  gridDropdowns = [
    {
      type: 'select',
      grid: 'col-12 col-ipad-landscape-3',
      name: 'grid_unit_length',
      validators: [],
      value: {
        value: true,
        label: 'Standard Wandraster (1.026 mm breit)'
      },
      content: [
        {
          value: true,
          label: 'Standard Wandraster (1.026 mm breit)'
        },
        {
          value: false,
          label: 'Fixmaß selbst bestimmen'
        }
      ]
    },
    {
      type: 'select',
      grid: 'col-12 col-ipad-landscape-3',
      name: 'grid_unit_depth',
      validators: [],
      value: {
        value: true,
        label: 'Standard Wandraster (1.026 mm breit)'
      },
      content: [
        {
          value: true,
          label: 'Standard Wandraster (1.026 mm breit)'
        },
        {
          value: false,
          label: 'Fixmaß selbst bestimmen'
        }
      ]
    }
  ];
  dimensions = [
    {
      headline: 'Raumlänge',
      subline: 'Der Systemraum darf nicht kürzer als 2.176 mm sein. Die maximale Raumlänge darf 30.980 mm nicht überschreiten.',
      type: 'range',
      value: 2176,
      name: 'length',
      validators: [{minLength: 2176}, {maxLength: 30980}],
      grid: 'col-10',
      content: [
        {
          floor: 2252,
          ceil: 30980,
          ticksArray: 28,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        },
        {
          floor: 2214,
          ceil: 30942,
          ticksArray: 28,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        },
        {
          floor: 2176,
          ceil: 30904,
          ticksArray: 28,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        },
        {
          floor: 2176,
          ceil: 30904,
          ticksArray: 28,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        }
      ]
    },
    {
      headline: 'Raumtiefe',
      subline: 'Der Systemraum darf eine Tiefe von 2.176 mm nicht unterschreiten. Die maximale Raumtiefe liegt bei 8.370 mm.',
      type: 'range',
      value: 2176,
      name: 'depth',
      validators: [{minLength: 2176}, {maxLength: 8370}],
      grid: 'col-10',
      content: [
        {
          floor: 2214,
          ceil: 8370,
          ticksArray: 6,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        },
        {
          floor: 2214,
          ceil: 8370,
          ticksArray: 6,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        },
        {
          floor: 2214,
          ceil: 8370,
          ticksArray: 6,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        },
        {
          floor: 2176,
          ceil: 8332,
          ticksArray: 6,
          translate: (value: number): string => {
            return value.toLocaleString() + ' mm';
          }
        }
      ]
    }
  ];
  height = [
    {
      type: 'select',
      value: 2600,
      name: 'firstFloor',
      label: 'Raumhöhe EG',
      validators: [{minLength: 2600}, {maxLength: 4000}],
      content: [2600, 2850, 3100, 4000],
      grid: 'col-12 col-mobile-landscape-6 col-desktop-smallest-3 col-ipad-portrait-6'
    },
    {
      type: 'select',
      value: 2600,
      name: 'secondFloor',
      label: 'Raumhöhe OG',
      depends: '2g',
      validators: [{minLength: 2600}, {maxLength: 3100}],
      content: [2600, 2850, 3100],
      grid: 'col-12 col-mobile-landscape-6 col-desktop-smallest-3 col-ipad-portrait-6'
    },
    {
      type: 'input',
      value: 500,
      name: 'stageHeight',
      label: 'Bühnenhöhe (Unterkante)',
      depends: 'stage',
      validators: [{minLength: 500}, {maxLength: 3100}],
      grid: 'col-12 col-mobile-landscape-6 col-desktop-smallest-3 col-ipad-portrait-6'
    }
  ];
  heightContent: {headline: string, subline: string, firstFloorLabel: string}[] = [
    {
      headline: 'Raumhöhe',
      subline: 'Wählen Sie die gewünschte Raumhöhe',
      firstFloorLabel: 'Raumhöhe EG'
    },
    {
      headline: 'Raum- und Bühnenhöhe',
      subline: 'Wählen Sie die gewünschte Raum- und Bühnenhöhe',
      firstFloorLabel: 'Raumhöhe'
    }
  ];
  hcIndex = 0;
  chosenDimensions = {
    headline: 'Gewählte Abmessungen',
    image: 'assets/images/tc/legend_dimensions.jpg',
    length: {
      name: 'length',
      label: 'Raumlänge'
    },
    depth: {
      name: 'depth',
      label: 'Raumtiefe'
    }
  };
  constructor() { }

  ngOnInit() {
    this.dimensions.forEach(elm => this.form.addControl(elm.name, new FormControl(elm.value)));
    this.gridDropdowns.forEach(elm => this.form.addControl(elm.name, new FormControl(elm.value)));
    this.height.forEach(height => this.form.addControl(height.name, new FormControl(height.value)));
  }
  ngAfterViewInit() {
    this.form.controls['variant'].valueChanges.subscribe(elm => {
      if ((elm === '2g' || elm === 'stage') && this.form.controls['firstFloor'].value === 4000) {
        this.form.controls['firstFloor'].setValue(3100);
      }

      this.hcIndex = elm === 'stage' ? 1 : 0;
    });
  }

  updateStageHeight(height) {
    if (height > 3100) {
      this.form.controls['stageHeight'].setValue(3100);
    } else if (height < 500) {
      this.form.controls['stageHeight'].setValue(500);
    } else {
      this.form.controls['stageHeight'].setValue(height);
    }
  }

  checkDimensions(min, max, event, field) {
    const val = event.target.valueAsNumber;

    if (val >= min && val <= max) {
      this.form.controls[field].setValue(val);
    } else {
      if (val <= min) {
        event.target.valueAsNumber = min;
        this.form.controls[field].setValue(min);
      } else if (val >= max) {
        event.target.valueAsNumber = max;
        this.form.controls[field].setValue(max);
      }
    }
  }
}
