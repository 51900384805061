<p class="align-center"
   (click)="scrollToFormSection($event)"
   [innerHTML]="'<a class=\'tc-reset-button\' href=\'#\'>Bitte denken Sie daran das vollständige Richtpreisangebot unten auf dieser Seite als PDF per E-Mail anzufordern.</a>'">
</p>

<div *ngIf="price" class="inner-wrap horizontal-center no-gutters">
  <div class="col-12 col-mobile-landscape-10 flex-helper">
    <div class="tc-reporting-price-offer">
      <div class="tc-reporting-price-offer__price">
        <h3>Richtpreis* für Ihren  neuen Systemraum  Trendline:</h3>
        <div class="tc-reporting-price-offer__price-summary">
          <p [innerHTML]="'Richtpreis: ' + ((price.withoutOptions + price.shipping) | currency: 'EUR')"></p>
          <p [innerHTML]="'Optionen: ' + (price.optionsOnly | currency: 'EUR')"></p>
        </div>
        <div class="tc-reporting-price-offer__overall-price" [innerHTML]="'ca. ' + ((price.overall + price.shipping)| currency: 'EUR')"></div>
        <p>
          <span [innerHTML]="'*zzgl. MwSt., inkl. Fracht und Montage '"></span>
          <span *ngIf="this.product.zipCode" [innerHTML]="'im PLZ-Bereich ' + this.product.zipCode.toString().slice(0, 2)"></span>
        </p>
      </div>
      <div class="tc-reporting-price-offer__listed-options">
        <h3>Gewählte Optionen</h3>
        <ul>
          <ng-container *ngFor="let item of optional;">
            <li *ngIf="item.type === 'checkbox-image'"
                [class.is-active]="conditionControl(item)">
              <span>{{item.label}}<small *ngIf="price.optionAmount[item.name] >= 1"
                                         [innerHTML]="'(' + price.optionAmount[item.name] + ' Stück)'"></small></span><span [innerHTML]="condition[conditionControl(item)]"></span>
            </li>
            <ng-container *ngIf="item.type === 'radio-image'">
              <li *ngFor="let radio of item.content"
                  [class.is-active]="conditionControl(item, radio['value'])">
                <span [innerHTML]="radio['label']"></span><span [innerHTML]="condition[conditionControl(item, radio['value'])]"></span>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
