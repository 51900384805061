<ng-container *ngFor="let elm of data; let i = index;"
              [ngSwitch]="elm.type">
  <div [className]="elm.grid"
       [attr.data-col]="elm.grid"
       [formGroup]="form">
    <ng-container *ngSwitchCase="'input'">
      <span [className]="'form-element__container'">
        <label [className]="'form-element__label'"
               [attr.for]="elm.type + '-' + sectionId + i"
               [class.form-element__label--required]="elm.validators.length > 0"
               [innerHTML]="elm['label']"></label>
        <input class="input input--gray"
               [id]="elm.type + '-' + sectionId + i"
               [placeholder]="elm.placeholder"
               [type]="elm.variant"
               [formControlName]="elm.name"
               [name]="elm.name" />
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'range'">
      <span [className]="'form-element__container'">
        <label [className]="'form-element__label'"
               [attr.for]="elm.type + '-' + sectionId + i"
               [innerHTML]="elm['label']"></label>
        <ng-template #defaultRangeTemplate>
          <input class="input input--gray"
                 [id]="elm.type + '-' + sectionId + i"
                 [placeholder]="elm.placeholder"
                 [type]="elm.type"
                 [formControlName]="elm.name"
                 [name]="elm.name" />
        </ng-template>
        <ng-template [ngTemplateOutlet]="rangeTemplate || defaultRangeTemplate"
                     [ngTemplateOutletContext]="{ name: elm.name, value: elm['value'], content: elm.content, placeholder: elm.placeholder, labelForId: elm.type + '-' + sectionId + i }"></ng-template>
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
      <span [className]="'form-element__container'">
        <label [className]="'form-element__label'"
               [attr.for]="elm.type + '-' + sectionId + i"
               [class.form-element__label--required]="elm.validators.length > 0"
               [innerHTML]="elm['label']"></label>
        <div class="select-dropdown__container">
          <ng-template #defaultSelectTemplate>
            <select [id]="elm.type + '-' + sectionId + i"
                    [class]="'select-dropdown'"
                    [formControlName]="elm.name">
              <option *ngFor="let item of elm.content;"
                      [value]="item['value']"
                      [innerHTML]="item['label']"></option>
            </select>
          </ng-template>
          <ng-template
            [ngTemplateOutlet]="selectTemplate || defaultSelectTemplate"
            [ngTemplateOutletContext]="{ name: elm.name, items: elm.content, placeholder: elm.placeholder, labelForId: elm.type + '-' + sectionId + i }"></ng-template>
        </div>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <span [className]="'form-element__container form-element__container--no-gap-top'">
        <label [className]="'form-element__label'"
               [attr.for]="elm.type + '-' + sectionId + i"
               [innerHTML]="elm['label']"></label>
        <textarea [className]="'textarea'"
                  [id]="elm.type + '-' + sectionId + i"
                  [formControlName]="elm.name"
                  [name]="elm.name"></textarea>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <label [className]="'form-element__container checkbox__container'"
             [attr.for]="elm.type + '-' + sectionId + i">
        <span [className]="'checkbox'"
              [class.checkbox--error]="form.controls[elm.name].errors && form.controls[elm.name].touched">
          <input [type]="elm.type"
                 [id]="elm.type + '-' + sectionId + i"
                 [formControlName]="elm.name"
                 (change)="form.controls[elm.name].setValue($event.target['checked']);"
                 [required]="elm.validators.length > 0" />
          <ng-template [ngTemplateOutlet]="checkboxIconTemplate"></ng-template>
        </span>
        <span [className]="'checkbox__label-text'"
              [class.form-element__label--required]="elm.validators.length > 0"
              [innerHTML]="elm['label']"></span>
      </label>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox-image'">
      <div [class]="'form-image'">
        <label [className]="'form-element__container checkbox__container form-image__container'"
               [attr.for]="elm.type + '-' + sectionId + i"
               [style.background-image]="'url(' + elm.content['img'] + ')'">
        <span [className]="'checkbox checkbox--image'"
              [class.checkbox--error]="form.controls[elm.name].errors && form.controls[elm.name].touched">
          <input [type]="'checkbox'"
                 [id]="elm.type + '-' + sectionId + i"
                 [formControlName]="elm.name"
                 (change)="form.controls[elm.name].setValue($event.target['checked']);"
                 [required]="elm.validators.length > 0" />
          <ng-template [ngTemplateOutlet]="checkboxIconTemplate"></ng-template>
        </span>
          <span [className]="'checkbox__label-text'"
                [class.form-element__label--required]="elm.validators.length > 0"
                [innerHTML]="elm['label']"></span>
          <div *ngIf="elm.content['info']"
               class="svg-icon svg-icon--white form-image__info-icon"
               (click)="infoContent.emit(elm.content['info']); $event.stopPropagation(); $event.preventDefault();"
               [inlineSVG]="'info.svg'"></div>
        </label>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'radio-image'">
      <div class="col-12 col-mobile-landscape-6 col-ipad-landscape-3"
           *ngFor="let radio of elm.content; let t = index;">
        <div [class]="'form-image'">
          <label [className]="'form-element__container radio__container form-image__container'"
                 [attr.for]="elm.type + '-' + sectionId + i + t" [style.background-image]="'url(' + radio['img'] + ')'">
            <span [className]="'radio radio--image'"
                  [class.checkbox--error]="form.controls[elm.name].errors && form.controls[elm.name].touched">
              <input [type]="'radio'"
                     [id]="elm.type + '-' + sectionId + i + t"
                     [formControlName]="elm.name"
                     [value]="radio['value']"
                     (change)="form.controls[elm.name].setValue(radio['value']);"
                     [checked]="form.controls[elm.name]['value'] === radio['value']"
                     [required]="elm.validators.length > 0" />
              <ng-template [ngTemplateOutlet]="radioIconTemplate"></ng-template>
            </span>
            <span [className]="'radio__label-text'"
                  [class.form-element__label--required]="elm.validators.length > 0"
                  [innerHTML]="radio['label']"></span>
            <div *ngIf="radio['info']" class="svg-icon svg-icon--white form-image__info-icon"
                 (click)="infoContent.emit(radio['info']); $event.stopPropagation(); $event.preventDefault();"
                 [inlineSVG]="'info.svg'"></div>
          </label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'submit'">
      <div [className]="'form-element__container'">
        <button class="button button--submit button--with-icon"
                [type]="elm.type"
                [disabled]="form.invalid === true">{{elm['label']}}
          <ng-template [ngTemplateOutlet]="btnIconTemplate"></ng-template>
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'html'">
      <div [className]="'form-element__container form-element__container--text center'" [innerHTML]="elm.content">
      </div>
    </ng-container>
  </div>
</ng-container>
