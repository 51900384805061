import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pull-down',
  templateUrl: './pull-down.component.html'
})
export class PullDownComponent implements OnInit {

  hash = 'pulldown-' + Math.random().toString(36).substring(7);

  constructor() { }

  ngOnInit(): void {
  }

}
